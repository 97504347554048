import * as Icon from 'react-feather';

const SidebarAccount = [
  {
    title: 'Dashboard',
    href: '/Dashboard/AccountDashboard',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Home/>,
    id: 1,
  },
  {
    title: ' Payments',
    href: '/Dashboard/AccountDashboard',
    icon: <Icon.DollarSign/>,
    id: 2,
    collapisable: true,
    children: [
      {
        title: 'Employee Salary',
        href: '/PayModule/EmployeeSalaryList',
        icon: <Icon.Award />,
      },
    ]
  },
  // {
  //   title: 'Billing',
  //   href: '/Dashboard/AccountDashboard',
  //   icon: <Icon.DollarSign/>,
  //   id: 3,
  // },
  // {
  //   title: 'Sales/Accounts',
  //   href: '/Dashboard/AccountDashboard',
  //   icon: <Icon.Target/>,
  //   id: 4,
  // },
  // {
  //   title: 'Wallet',
  //   href: '/Dashboard/AccountDashboard',
  //   icon: <Icon.Pocket/>,
  //   id: 5,
  // },
  // {
  //   title: 'Financial',
  //   href: '/Dashboard/AccountDashboard',
  //   icon: <Icon.File/>,
  //   id: 6,
  // },
];

export default SidebarAccount;
