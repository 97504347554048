import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  zoneDeleteResponse: {
    status: false,
    message: '',
  },
  zoneDeleteSucces: false,
  error: '',
  loading: false,
};
 
export const getCancelledOrderAdmin = createAsyncThunk('adminOrderSlice/getCancelledOrderAdmin', async () => {
  const response = await fetch('https://app.osbrm.com/GetCancelledOrders', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getCompletedOrderAdmin = createAsyncThunk('adminOrderSlice/getCompletedOrderAdmin', async () => {
  const response = await fetch('https://app.osbrm.com/api/Professional/GetCompletedOrders', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getRefferalHistoryAdmin = createAsyncThunk('adminOrderSlice/getZonalList', async () => {
    const response = await fetch('https://app.osbrm.com/GetReferralHistoryForCustomer', {
      method: 'GET',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
    });
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  });
 
export const adminOrderSlice = createSlice({
  name: 'adminOrderSlice',
  initialState,
  reducers: {
    FetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, zoneResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, zoneDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        zoneDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        zoneDeleteSucces: false,
        zoneDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const { FetchSuccess, FetchFailed, DeleteSuccess, DeleteFailed, DeleteInitial } =
  adminOrderSlice.actions;
export default adminOrderSlice.reducer;
