import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const initialState = {
  hrSliceDeleteResponse: {
    status: false,
    message: '',
  },
  updateResponse: false,
  error: '',
  loading: false,
};

export const addCandidate = createAsyncThunk('hrSlice/addCandidate', async (params) => {
  const data = {
    id: 0,
    firstName: `${params.fields.FirstName}`,
    middleName: `${params.fields.MiddleName}`,
    lastName: `${params.fields.LastName}`,
    phone: `${params.fields.mobileNumber}`,
    address: `${params.address}`,
    designationType: `${params.designation.value}`,
    offOnRole: `${params.selectedRole.value}`,
    cvFileName: `${params.imageFilename}`,
    cvFileUrl: `${params.proof}`,
    dob: `${params.fields.dob}`,
    pincode: `${params.fields.pincode}`,
    email: `${params.fields.email}`,
    isScreened: '',
    isInterview: '',
    isFinalised: '',
    isAppointed: '',
    totalExp: `${params.fields.experience}`,
  };

  const response = await fetch('https://app.osbrm.com/api/Hr/AddCandidates', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const addDocuments = createAsyncThunk('hrSlice/addDocuments', async (params) => {
  const data = {
    candidateId: `${params.candidateId}`,
    eduDocsFileName: `${params.educationDocsFileName}`,
    eduDocsFileUrl: `${params.educationDocs}`,
    aadharFileName: `${params.adharFileName}`,
    aadharFileUrl: `${params.adharImage}`,
    panFileName: `${params.panFileName}`,
    panFileUrl: `${params.panImage}`,
    imageName: `${params.profileFileName}`,
    imageUrl: `${params.profileImage}`,
    prevOrgDocsFileName: `${params.imageFilename}`,
    prevOrgDocsFileUrl: `${params.proof}`,
    expectedSal: `${params.fields.expectedSalary}`,
    offeredSal: `${params.fields.offeredSalary}`,
    previousSal: `${params.fields.previousSalary}`,
    expectedJoinDate: `${params.fields.joiningDate}`,
    verificationSts: '',
    bankAccNo: `${params.fields.accountNo}`,
    bankIFSC: `${params.fields.IFSC}`,
    pfAccNo: `${params.fields.pfAccNum}`,
    uanNo: `${params.fields.UAN}`,
    esiNo: `${params.fields.ESINum}`,
  };
  const response = await fetch('https://app.osbrm.com/api/Hr/AddCandidateDocs', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const updateDocuments = createAsyncThunk('hrSlice/updateDocuments', async (params) => {

  const data = {
    candidateId: `${params.candidateId}`,
    eduDocsFileName: `${params.educationDocsFileName ? params.educationDocsFileName : ''}`,
    eduDocsFileUrl: `${params.educationDocs ? params.educationDocs : ''}`,
    aadharFileName: `${params.adharFileName ? params.adharFileName : ''}`,
    aadharFileUrl: `${params.adharImage ? params.adharImage : ''}`,
    panFileName: `${params.panFileName ? params.panFileName : ''}`,
    panFileUrl: `${params.panImage ? params.panImage : ''}`,
    imageName: `${params.profileFileName ? params.profileFileName : ''}`,
    imageUrl: `${params.profileImage ? params.profileImage : ''}`,
    prevOrgDocsFileName: `${params.imageFilename ? params.imageFilename : ''}`,
    prevOrgDocsFileUrl: `${params.proof ? params.proof : ''}`,
    expectedSal: `${params.expectedSalary}`,
    offeredSal: `${params.offeredSalary}`,
    previousSal: `${params.previousSalary}`,
    expectedJoinDate: `${params.joiningDate}`,
    bankAccNo: `${params.accountNum}`,
    bankIFSC: `${params.IFSCcode}`,
    pfAccNo: `${params.pfAccNumber}`,
    uanNo: `${params.UAN}`,
    esiNo: `${params.ESINumber}`,
    verificationSts: '',
  };

  const response = await fetch('https://app.osbrm.com/api/Hr/UpdateCandidateDocs', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
/**
{
    "screened": "100",
    "interviewed": "80",
    "finalised": "40",
    "appointedTech": "20",
    "appointedEngg": "20"
}

 */
export const addRecrutingCount = createAsyncThunk('hrSlice/addRecrutingCount', async (params) => {
  const data = {
    id: 0,
    hrId: `${params.currentUser.Id}`,
    screeningProcess: `${params.Data.screened}`,
    interviewed: `${params.Data.interviewed}`,
    finalized: `${params.Data.finalised}`,
    appointedTech: `${params.Data.appointedTech}`,
    apointedEngg: `${params.Data.appointedEngg}`,
    createDate: `${params.localDatetime}`,
  };
  const response = await fetch('https://app.osbrm.com/api/Hr/AddIntoRecruitmentLog', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const updateProfessionalDetails = createAsyncThunk(
  'hrSlice/updateProfessionalDetails',
  async (params) => {
    const fullname = params.data.firstName.concat(' ', params.data.lastName);
    //
    const rowData = {
      fullName: fullname,
      roleId: params.data.professionRole.value,
      cityId: params.data.selectCity,
      stateId: params.data.selectState,
      pinCode: params.data.pincode,
      addressLine1: params.data.address,
      addressLine2: params.data.address,
      createdDate: '2023-07-17T10:05:50.317Z',
      phone: params.data.phoneNumber,
      email: params.data.emailAddress,
      birthDate: params.data.DOB,
      panNumber: params.data.panNumber,
      aadharNumber: params.data.adharNumber,
      vendorId: params.data.vendorID,
      professionalServicesDetailsList: [
        {
          serviceId: params.data.serviceID,
        },
      ],
    };

    const response = await fetch(
      `https://app.osbrm.com/api/Professional/UpdateProfessional?id=${params.data.userID}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
        body: JSON.stringify(rowData),
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getAllCandidates = createAsyncThunk('hrSlice/getAllCandidates', async () => {
  const response = await fetch('https://app.osbrm.com/api/Hr/GetCandidatesAllOrById', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getSalaryStructure = createAsyncThunk('hrSlice/getSalaryStructure', async () => {
  const response = await fetch('https://app.osbrm.com/api/Hr/GetSalaryStructure', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const addSalaryDetailsEmp = createAsyncThunk(
  'hrSlice/addSalaryDetailsEmp',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Hr/AddSalaryDetailsEmp?EmpId=${params.candidateID}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
        body: JSON.stringify(params.dataToSend),
      },
    );
    const res = await response.json();

    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const updateSalaryDetailsEmp = createAsyncThunk(
  'hrSlice/addSalaryDetailsEmp',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Hr/UpdateSalaryDetailsEmp?EmpId=${params.candidateID}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
        body: JSON.stringify(params.dataToSend),
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
//

export const getAttendanceList = createAsyncThunk('hrSlice/getAttendanceList', async (params) => {

  const month = params.selectMonth ? params.selectMonth : 0;
  const year = params.selectYear ? params.selectYear : 0;
  const empId = params.currentUser ? params.currentUser.Id : 0
  const response = await fetch(`https://app.osbrm.com/api/Hr/GetAttendance?month=${month}&Year=${year}&EmpId=${empId}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getDatesAndAttendanceCount = createAsyncThunk('hrSlice/getDatesAndAttendanceCount', async (params) => {
  const month = params.selectMonth ? params.selectMonth : 0;
  const year = params.selectYear ? params.selectYear : 0;
  const empId = params.empId ? params.empId : 0
  const response = await fetch(`https://app.osbrm.com/api/Hr/GetDatesAndAttendanceCnt?action1=${params.action}&month=${month}&Year=${year}&EmpId=${empId}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getSalaryStructureEmp = createAsyncThunk(
  'hrSlice/getSalaryStructureEmp',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Hr/GetSalaryStructureEmp?EmpId=${params.candidateID}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getCandidateDocs = createAsyncThunk('hrSlice/getCandidateDocs', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Hr/GetCandidateDocs?id=${params}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const getAllCandidatesByAction = createAsyncThunk(
  'hrSlice/getAllCandidatesByAction',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Hr/GetCandidatesByAction?Action=${params}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetRecruitmentLog = createAsyncThunk(
  'hrSlice/GetRecruitmentLog',
  async () => {
    const response = await fetch(
      `https://app.osbrm.com/api/Hr/GetRecruitmentLog`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deleteCandidate = createAsyncThunk('hrSlice/delete', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Hr/DeleteCandidates?id=${params.deletId}`,
    {
      method: 'DELETE',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const sendForApproval = createAsyncThunk('hrSlice/sendForApproval', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Hr/RecruitmentProcess?Action=${params.action}&id=${params.id}`,
    {
      method: 'PUT',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const changeRecruitingStatus = createAsyncThunk(
  'hrSlice/changeRecruitingStatus',
  async (params) => {
    const rejectRemark = params.RejectRemark ? params.RejectRemark : '';
    const revSal = params.RevSal ? params.RevSal : 0;
    const revJDate = params.RevJoDate ? params.RevJoDate : '';
    const response = await fetch(
      `https://app.osbrm.com/api/Hr/RecruitmentProcess?Action=${params.action}&AccFlag=${params.AccFlag}&id=${params.id}&RejectRemark=${rejectRemark}&RevSal=${revSal}&RevJoDate=${revJDate}`,
      {
        method: 'PUT',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const getAllVendors = createAsyncThunk('hrSlice/getAllVendors', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetAllVendor`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getVendorDetails = createAsyncThunk('hrSlice/getVendorDetails', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetVendorByID/${params.value}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const GetUnAssignedOrdersForProffessional = createAsyncThunk(
  'hrSlice/GetAssignedOrdersForProffessional',
  async () => {
    const response = await fetch(`https://app.osbrm.com/GetUnassignedOrders`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const hrSlice = createSlice({
  name: 'hrSlice',
  initialState,
  reducers: {
    hrFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    hrFetchFailed(state, action) {
      return { ...state, hrSliceResponse: { status: true, message: action.payload } };
    },
    hrDeleteSuccess(state) {
      return { ...state, loading: false, updateResponse: true };
    },
    hrDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        hrSliceDeleteResponse: { status: true, message: action.payload },
      };
    },
    hrDeleteInitial(state) {
      return {
        ...state,
        updateResponse: false,
        hrSliceDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const { hrFetchSuccess, hrFetchFailed, hrDeleteSuccess, hrDeleteFailed, hrDeleteInitial } =
  hrSlice.actions;
export default hrSlice.reducer;
