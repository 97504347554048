import React, { useState, useEffect } from 'react';

import {
  // useNavigate,
  Link,
} from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FileText, Briefcase } from 'react-feather';
import user1 from '../../assets/images/users/user4.jpg';
import { getVendorWalletHistoryById,getACWalletHistoryById } from '../../slices/VendorSlice/vendorSlice';

const ProfileDD = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.user);
  const [displayBal, setDisplayBal] = useState(false);
  const [history, setHistory] = useState([]);
  const [ACHistory , setACHistory] = useState([])
  const [balance, setBalance] = useState(0);

  const getWalletBalance = (params) => {
    const latestReferralAmount = params.reduce(
      (latest, current) => {
        const currentDate = new Date(current.TransDate);
        return currentDate > latest.TransDate
          ? { TransDate: currentDate, balance: current.WalletBalanceAfterUpdate }
          : latest;
      },
      { TransDate: new Date(0), balance: 0 },
    );

    return latestReferralAmount.balance;
  };
  const getWalletBalanceAC = (params) => {
    const latestReferralAmount = params.reduce(
      (latest, current) => {
        const currentDate = new Date(current.CreateDate);
        return currentDate > latest.CreateDate
          ? { CreateDate: currentDate, balance: current.WalletAfterUpdate }
          : latest;
      },
      { CreateDate: new Date(0), balance: 0 },
    );

    return latestReferralAmount.balance;
  };
  useEffect(()=>{
    dispatch(getACWalletHistoryById())
    .unwrap()
    .then((res) => {
      if (res.response_code == '1') {
       setACHistory(res.Data)
        // navigate('/Subscription/GetSubscriptionDetails');
      } else {
        console.log('error')
      }
    })
    .catch(() => {
      console.log('error')
    });
  },[])
  const showBalance = () => {
    if(currentUser.Role === 'VE'){
      setBalance(getWalletBalance(history));
    }else{
      setBalance(getWalletBalanceAC(ACHistory))
    }
    setDisplayBal(true);
  };
  useEffect(() => {
    dispatch(getVendorWalletHistoryById(currentUser))
      .unwrap()
      .then((res) => {
        setHistory(res.ProductDetails ? res.ProductDetails : []);
      });
  }, []);
  // const navigate = useNavigate();
  //  const handleChange = () =>{
  //   navigate('/Dashboard/ChangPasswordForProfessional')
  //  }
  return (
    <div>
      <div className="d-flex gap-3 p-3 text-white rounded-top bg-grey-theme  pt-2 align-items-center">
        <img src={user1} alt="user" className="mt-2 rounded-circle" width="60" />
        <span>
          <h5 className="mb-0">{currentUser.FullName}</h5>
          <small className="fs-6 opacity-50">
            {currentUser ? (
              <small className="pb-4 d-block"> {currentUser.Email}</small>
            ) : (
              <small className="pb-4 d-block">User Not Login</small>
            )}
          </small>
        </span>
      </div>
      <Link
        className="ms-auto text-decoration-none link-primary fw-normal"
        to="/Dashboard/ChangPasswordForProfessional"
      >
        <DropdownItem className="px-4 py-3">
          <FileText size={20} />
          &nbsp; Change Password
        </DropdownItem>
      </Link>
      {currentUser.Role === 'IM' || currentUser.Role === 'MR' || currentUser.Role === 'TE' || currentUser.Role === 'CD' || currentUser.Role === 'A'? (
        <></>
      ) : currentUser.Role === 'VE' ||  currentUser.Role === 'AC' ? (
        <div className="px-4 py-3" onClick={showBalance}>
          <Briefcase size={20} />
          &nbsp; {displayBal ? `₹ ${balance}` : 'My Balance'}
        </div>
      ) : (
        <DropdownItem className="px-4 py-3">
          <Briefcase size={20} />
          &nbsp; My Balance
        </DropdownItem>
      )}
      <DropdownItem divider />
      {/* <DropdownItem className="px-4 py-3">
        <Star size={20} />
        &nbsp; My Balance 
      </DropdownItem>
      <DropdownItem divider /> */}
    </div>
  );
};

export default ProfileDD;
