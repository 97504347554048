import * as Icon from 'react-feather';

const SidebarMarketing = [
  {
    title: 'Dashboard',
    href: '/Dashboard/MarketingDashboard',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Home />,
    id: 1,
  },
  // 
  {
    title: 'Coupon',
    href: '/Dashboard/MarketingDashboard',
    icon: <Icon.Activity />,
    id: 2,
    collapisble: true,
    children: [
      {
        title: 'Add Coupons',
        href: '/Coupons/AddCoupons',
        icon: <Icon.Disc/>,
      },
      {
        title: 'Coupons List',
        href: '/Coupons/CouponsList',
        icon: <Icon.Award/>,
      },
      {
        title: 'Coupons History',
        href: '/Coupons/CouponsHistory',
        icon: <Icon.Award/>,
      },
    ],
  },
  // {
  //   title: 'Share & Refer',
  //   href: '/share/sharerefral',
  //   icon: <Icon.Share2 />,
  //   id: 3,
  // },
  // {
  //   title: 'Analytics Reports',
  //   href: '/Dashboard/MarketingDashboard',
  //   icon: <Icon.List/>,
  //   id: 4,
  //   collapisble: true,
  //   children: [
  //     {
  //       title: 'Report 1',
  //       href: '/Dashboard/MarketingDashboard',
  //       icon: <Icon.Disc/>,
  //     },
  //     {
  //       title: 'Report 2',
  //       href: '/Dashboard/MarketingDashboard',
  //       icon: <Icon.Award/>,
  //     },
  //     {
  //       title: 'Report 3',
  //       href: '/Dashboard/MarketingDashboard',
  //       icon: <Icon.Award/>,
  //     },
  //   ],
  // },
  // {
  //   title: 'Sales target',
  //   href: '/Dashboard/MarketingDashboard',
  //   icon: <Icon.ShoppingCart/>,
  //   id: 5,
  // },
];

export default SidebarMarketing;
