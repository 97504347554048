// import React from 'react';
// import { Link } from 'react-router-dom';
// import logo from "../../assets/images/logos/logohr.png";

// const Logo = () => {
//   return (
//     <Link to="/" className="d-flex align-items-center gap-2">
//         <img src={logo} alt="profile"  width="auto" height="50" style={{marginLeft:25}}></img>
//     </Link>
//   );
// };

// export default Logo;
import React from 'react';
// import { Link } from 'react-router-dom';
import logo from "../../assets/images/logos/OSBRMLogo.png";

const logoWeb = () => {
  return (
    <div  className="d-flex align-items-center gap-2">
        <img src={logo} alt="profile"  width="auto" height="45" ></img>
    </div>
  );
};

export default logoWeb;


