import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

function generateUniqueTabId() {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
}

// Check if tabId is already in sessionStorage, if not, generate and store it
let tabId = sessionStorage.getItem('tabId');
if (!tabId) {
  tabId = generateUniqueTabId();
  sessionStorage.setItem('tabId', tabId);
}

const CryptoJS = require('crypto-js'); 

const key =
  'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY4Mzk2MzQwNSwiaWF0IjoxNjgzOTYzNDA1fQ.eHXysz4LDwqHLjsVUr5RwnYdSIw72Wf7Mv7JlDJh2AI';

const user = JSON.parse(sessionStorage.getItem(`user${tabId}`))
  ? JSON.parse(
      CryptoJS.AES.decrypt(JSON.parse(sessionStorage.getItem(`user${tabId}`)), key).toString(
        CryptoJS.enc.Utf8,
      ),
    )
  : null;

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: '' };

export const login = createAsyncThunk('user/login', async (data) => {
  const response = await fetch(
    `https://app.osbrm.com/adminlogin?UserName=${data.UserName.UserName}&Password=${data.UserName.Password}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const res = await response.json();
  const UserData = JSON.parse(res);
  if (UserData.Token) {
      const Login = CryptoJS.AES.encrypt(JSON.stringify(UserData.data[0]), key).toString();
    const userToken = CryptoJS.AES.encrypt(JSON.stringify(UserData.Token), key).toString();
    // localStorage.setItem('user', JSON.stringify(Login));
    sessionStorage.setItem(`token${tabId}`, JSON.stringify(userToken));
    sessionStorage.setItem(`user${tabId}`, JSON.stringify(Login));
    return UserData;
  }
  if (UserData.status === 'false') {
    return UserData;
  }
  return UserData;
});

export const logout = () => {
  sessionStorage.removeItem(`user${tabId}`);
  sessionStorage.removeItem(`token${tabId}`);
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      return { ...state, user: action.payload.data[0] };
    },
    loginFailed(state, action) {
      return { ...state, loginResponse: { status: true, msg: action.payload } };
    },
    setLoginStatesFalse(state) {
      return { ...state, fetchError: false };
    },
  },
});
export const { loginSuccess, loginFailed } = userSlice.actions;
export default userSlice.reducer;
