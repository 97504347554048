
import { useRoutes  } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';


const App = () => {
  const routing = useRoutes(Themeroutes);
  // const navigate = useNavigate();
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  // window.history.pushState(null, null, window.location.href);
  // window.onpopstate = () => {
  //   navigate.go(1);
  // };
  return (
    // fallback={<Loader />}
   
      <div className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}dir={direction ? 'rtl' : 'ltr'}>
        <ThemeSelector  />
        {routing}
      </div>
  );
};

export default App;
