import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const initialState = {
  vendorSliceDeleteResponse: {
    status: false,
    message: '',
  },
  updateResponse: false,
  error: '',
  loading: false,
};

export const addVendor = createAsyncThunk('vendorSlice/addVendor', async (params) => {
  const fullname = params.fields.FirstName.concat(' ', params.fields.LastName);
  const data = {
    fullName: `${fullname}`,
    cityId: `${params.selectedCity.value}`,
    stateId: `${params.selectedState.value}`,
    pinCode: `${params.selectPin.value}`,
    addressLine1: `${params.address}`,
    addressLine2: `${params.address}`,
    createdDate: `${params.fields.dob}`,
    phone: params.fields.mobileNumber,
    email: `${params.fields.email}`,
    mobileNo: params.fields.mobileNumber,
    birthDate: `${params.fields.dob}`,
    panNumber: `${params.fields.pan_number}`,
    aadharNumber: `${params.fields.adhar_number}`,
    gstno: `${params.fields.gstNumber}`,
    password: 'ketan@8308',
    aadharProof: `${params.proof}`,
    aadharFileName: `${params.imageFilename}`,
    vendorZoneList: [
      {
        zoneId: `${params.zonalSelect.value}`,
        id: 0,
        vendorId: 0,
      },
    ],
  };

  const response = await fetch('https://app.osbrm.com/api/Vendor/AddVendor', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'True') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
// convertedData.map(async (item) => {
//   const {
//     fullname,
//     cityid,
//     stateid,
//     pincode,
//     addressline1,
//     addressline2,
//     mobileno,
//     email,
//     birthdate,
//     pannumber,
//     aadharnumber,
//     gstno,
//   } = item;

export const addVendorImport = createAsyncThunk('vendorSlice/addVendorImport', async (params) => {

  const data = params.map((item) => ({
    fullName: `${item.fullname}`,
    cityName: `${item.cityname}`,
    stateName: `${item.statename}`,
    pinCode: `${item.pincode}`,
    addressLine1: `${item.addressline1}`,
    addressLine2: `${item.addressline2}`,
    createdDate: `${item.createddate}`,
    phone: `${item.mobileno}`,
    mobileNo: `${item.mobileno}`,
    email: `${item.email}`,
    birthDate: `${item.birthdate}`,
    panNumber: `${item.pannumber}`,
    aadharNumber: `${item.aadharnumber}`,
    gstno: `${item.gstno}`,
    password: 'ketan@8308',
    aadharProof:
      'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=',
    aadharFileName: 'temp.jpeg',
  }));


  const response = await fetch('https://app.osbrm.com/api/Excel/AddVendorExcel', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'True') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const updateVendorDetails = createAsyncThunk(
  'vendorSlice/updateVendorDetails',
  async (params) => {
    const fullname = params.data.firstName.concat(' ', params.data.lastName);
    const rowData = {
      fullName: `${fullname}`,
      cityId: `${
        params.data.selectedCity.value ? params.data.selectedCity.value : params.data.selectedCity
      }`,
      stateId: `${
        params.data.selectedState.value
          ? params.data.selectedState.value
          : params.data.selectedState
      }`,
      pinCode: `${params.data.selectPin.value}`,
      addressLine1: `${params.data.address}`,
      addressLine2: `${params.data.address}`,
      createdDate: '2023-04-27T10:39:18.674Z',
      phone: `${params.data.phoneNumber}`,
      mobileNo: `${params.data.phoneNumber}`,
      email: `${params.data.emailAddress}`,
      birthDate: `${params.data.DOB}`,
      panNumber: `${params.data.panNumber}`,
      aadharNumber: `${params.data.adharNumber}`,
      gstno: `${params.data.gstNumber}`,
      aadharProof: `${params.data.proof ? params.data.proof : ''}`,
      aadharFileName: `${params.data.imageFilename}`,
      password: 'myPassword',
      vendorZoneList: [
        {
          id: params.data.id,
          vendorId: params.data.vendorId,
          zoneId: params.data.zonalSelect.value
            ? params.data.zonalSelect.value
            : params.data.zonalSelect,
        },
      ],
    };

    const response = await fetch(`https://app.osbrm.com/api/Vendor/UpdateVendor/${params.userID}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(rowData),
    });
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
/*{
  "vendorId": 0,
  "id": 0,
  "productName": "string",
  "mrp": 0,
  "discount": 0,
  "discountType": "string",
  "actualPrice": 0,
  "productImageUrl": "string",
  "productImage": "string",
  "quantity": 0
} */
export const addProducts = createAsyncThunk('vendorSlice/addProducts', async (params) => {
  const data = {
    // vendorId: `${params.currentUser.Id}`,
    id: '0',
    productName: params.productName,
    mrp: params.price,
    discount: params.discount ? params.discount : 0,
    discountType: params.select,
    actualPrice: params.actualPrice,
    productImageUrl: params.bannerImage,
    productImage: params.imageFilename,
    quantity: params.qty,
    gst: `${params.gst}`,
  };

  const response = await fetch('https://app.osbrm.com/api/Vendor/AddProducts', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
/*{
  "vendorId": 0,
  "id": 0,
  "productName": "string",
  "mrp": 0,
  "discount": 0,
  "discountType": "string",
  "actualPrice": 0,
  "productImageUrl": "string",
  "productImage": "string",
  "quantity": 0
        productName,
        imageFilename,
        bannerImage,
        discount,
        price,
        select,
        quantity,
        currentUser
} */
export const UpdateProductDetails = createAsyncThunk(
  'vendorSlice/UpdateProductDetails',
  async (params) => {
    const data = {
      // vendorId: `${params.currentUser.Id}`,
      id: `${params.id}`,
      productName: params.productName,
      mrp: params.price,
      discount: params.discount,
      discountType: params.select,
      actualPrice: params.price,
      productImageUrl: params.bannerImage,
      productImage: params.imageFilename,
      quantity: params.quantity,
      gst: params.gst,
    };
    const response = await fetch('https://app.osbrm.com/api/Vendor/UpdateProducts', {
      method: 'PUT',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const AddProductToCart = createAsyncThunk('vendorSlice/AddProductToCart', async (params) => {
  const data = {
    productId: `${params.productDetails.id}`,
    vendorId: `${params.currentUser.Id}`,
    requestId: '',
    professionalId: '',
    requestQty: `${params.requestQuantity}`,
    rejFlag: '',
    resRole: '',
    iorderId: '',
    productName: `${params.productDetails.ProductName}`,
    actualPrice: `${params.productDetails.ActualPrice}`,
  };
  const response = await fetch(`https://app.osbrm.com/api/Vendor/AddIntoRequestCart`, {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getVenderProduct = createAsyncThunk('vendorSlice/addProducts', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetProductsByVendorId`, {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getCartDetails = createAsyncThunk('vendorSlice/getCartDetails', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Vendor/GetProductReqCheckoutData?vendorId=${params.currentUser.Id}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const RequestProductByVendor = createAsyncThunk(
  'vendorSlice/RequestProductByVendor',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/RequestProductByVendor?VendorId=${params.currentUser.Id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const UpdateVendorCartQuantity = createAsyncThunk(
  'vendorSlice/UpdateVendorCartQuantity',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/UpdateVendorCartRquestQty?id=${params.tempId}&Qty=${params.changedQuantity}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
// vendor Wallet
export const getVendorWalletHistory = createAsyncThunk(
  'webservice/getVendorWalletHistory',
  async () => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/GetVendorWalletHistById?id=${''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getVendorWalletHistoryById = createAsyncThunk(
  'webservice/getVendorWalletHistoryById',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/GetVendorWalletHistById?id=${params.Id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getACWalletHistoryById = createAsyncThunk(
  'webservice/getVendorWalletHistoryById',
  async () => {
    const response = await fetch(`https://app.osbrm.com/api/Account/GetAccountWalletHist`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getInvoiceDetailsForVendor = createAsyncThunk(
  'webservice/getVendorWalletHistoryById',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/GetRequestOrderInvoiceData?IOrderId=${params.orderID}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getProfessionalListVender = createAsyncThunk(
  'vendorSlice/addProducts',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/GetProfessionalListForVendor?VendorMobileNo=${params.currentUser.Phone}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deletVenderProduct = createAsyncThunk('vendorSlice/deleteVendor', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Vendor/DeleteProduct?id=${params.deletId}`,
    {
      method: 'POST',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const AddProductsExcel = createAsyncThunk('vendorSlice/AddProductsExcel', async (params) => {

  const data = params.map((item) => ({
    id: 0,
    productName: `${item.productname}`,
    mrp: item.mrp,
    discount: item.discount,
    discountType: `${item.discounttype}`,
    actualPrice: item.actualprice,
    quantity: item.quantity,
    inventoryQty: item.quantity,
    gst: item.gst,
    status: item.status,
  }));

  const response = await fetch('https://app.osbrm.com/api/Excel/AddProductsExcel', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'True') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const DeReActivateProduct = createAsyncThunk(
  'vendorSlice/DeReActivateProduct',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeReActivateAll?action1=Product&id=${params.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const cancelProductRequest = createAsyncThunk(
  'vendorSlice/cancelProductRequest',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/RequestCancelByVendor?IOrderId=${params.IOrderId}&VendorId=${params.RequestBy}`,
      {
        method: 'PUT',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const deleteProductCart = createAsyncThunk(
  'vendorSlice/deleteProductCart',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/DeleteProductCart?id=${params.data.id}&ProductId=${params.data.ProductId}&VendorId=${params.data.VendorId}`,
      {
        method: 'POST',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deleteAllProductCart = createAsyncThunk(
  'vendorSlice/deleteAllProductCart',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/DeleteAllProductsCart?VendorId=${params.currentUser.Id}`,
      {
        method: 'POST',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deleteVendor = createAsyncThunk('vendorSlice/deleteVendor', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Vendor/DeleteVendor?id=${params.deletId}`,
    {
      method: 'DELETE',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const addAmountToVendor = createAsyncThunk(
  'vendorSlice/addAmountToVendor',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/AdminPayForVendorWallet?Phone=${params.tempMobile}&Amount=${params.tempAmount}`,
      {
        method: 'POST',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getProfessionalDetails = createAsyncThunk(
  'vendorSlice/GetProfessionalBy',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/GetProfessionalByID/${params.userID}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

// VENDORS API LIST
export const getAllVendors = createAsyncThunk('vendorSlice/getAllVendors', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetAllVendor`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const DeReActivateVendor = createAsyncThunk(
  'vendorSlice/DeReActivateVendor',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeReActivateAll?action1=Vendor&id=${params.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getVendorDetails = createAsyncThunk('vendorSlice/getVendorDetails', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetVendorByID/${params.userID}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getRequestTrack = createAsyncThunk('vendorSlice/getRequestTrack', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Vendor/TrackRequest?Action=AllRequest&VendorId=${params.currentUser.Id}`,
    {
      method: 'GET',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const VendorReqWalletPay = createAsyncThunk(
  'vendorSlice/VendorReqWalletPay',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/VendorReqWalletPay?VendorId=${params.vendorID}&IOrderId=${params.orderId}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetUnAssignedOrdersForProffessional = createAsyncThunk(
  'technicianLeadSlice/GetAssignedOrdersForProffessional',
  async () => {
    const response = await fetch(`https://app.osbrm.com/GetUnassignedOrders`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getProfessionalOrders = createAsyncThunk(
  'vendorSlice/getProfessionalOrders',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/GetOrderDetailsByVendorTechWise?VendorId=${params.currentUser.Id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getProfessionalOrderDetails = createAsyncThunk(
  'vendorSlice/getProfessionalOrderDetails',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/GetOrderDetailsByVendorTechWiseView?OrderId=${params.Order_Id}&TechnicianId=${params.id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const vendorSlice = createSlice({
  name: 'vendorSlice',
  initialState,
  reducers: {
    venFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    venFetchFailed(state, action) {
      return { ...state, vendorSliceResponse: { status: true, message: action.payload } };
    },
    venDeleteSuccess(state) {
      return { ...state, loading: false, updateResponse: true };
    },
    venDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        vendorSliceDeleteResponse: { status: true, message: action.payload },
      };
    },
    venDeleteInitial(state) {
      return {
        ...state,
        updateResponse: false,
        vendorSliceDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  venFetchSuccess,
  venFetchFailed,
  venDeleteSuccess,
  venDeleteFailed,
  venDeleteInitial,
} = vendorSlice.actions;
export default vendorSlice.reducer;
