import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/json;',
};
const initialState = {
  subcategoryDeleteResponse: {
    status: false,
    message: '',
  },
  subcategoryDeleteSucces: false,
  error: '',
  loading: false,
};
export const subcategory = createAsyncThunk('subcategory/subcategory', async (params) => {
  const data = {
    subCategoryName: params.subcategoryName.subcategoryName,
    headingField: params.subcategoryName.Heading,
    categoryId: params.select,
    imagebase64Data: `${params.proof}`,
    fileName: params.imageFilename,
  };
  const response = await fetch('https://app.osbrm.com/api/Common/AddSubCategory', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const AddSubCategoryExcel = createAsyncThunk(
  'subcategory/AddSubCategoryExcel',
  async (params) => {

    const data = params.map((item) => ({
      subCategoryName: `${item.subcategoryname}`,
      headingField: `${item.heading}`,
      categoryName: `${item.categoryname}`,
      imagebase64Data: 'aaa',
      fileName: '',
    }));

    const response = await fetch('https://app.osbrm.com/api/Excel/AddSubCategoryExcel', {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();

    if (res.status === 'True') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getsubCategory = createAsyncThunk('subcategory/get', async () => {
  const response = await fetch('https://app.osbrm.com/api/Common/GetAllSubCategory', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const DeReActivateSubCategory = createAsyncThunk(
  'subcategory/DeReActivateSubCategory',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeReActivateAll?action1=SubCategory&id=${params.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const subcategoryDelete = createAsyncThunk(
  'subcategory/subcategoryDelete',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Common/DeleteSubCategory?id=${params.deletId}`,
      {
        method: 'DELETE',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getSubCatDetails = createAsyncThunk('subcategory/getSubCatDetails', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Common/GetSubCategoryByID/${params}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const subCategoryUpdate = createAsyncThunk('category/subCategoryUpdate', async (params) => {
  const data = {
    subCategoryName: params.subCategoryName,
    headingField: params.categoryHeading,
    // categoryId: params.categoryID,
    categoryId: params.selectCat.value,
    imagebase64Data: params.proof,
    fileName: params.imageFilename,
  };

  const response = await fetch(
    `https://app.osbrm.com/api/Common/UpdateSubCategory/${params.subCategoryID}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(data),
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const categoryStatus = createAsyncThunk('subcategory/status', async (data) => {
  const response = await fetch(
    'http://uat.prasadsolution.com/app/ProductCategory.asmx/changeSubCategoryStatus',
    {
      method: 'POST',
      headers: header,
      body: `sid=${data.statusId}&Authenticationkey=${'123456'}`,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const SubCategorySlice = createSlice({
  name: 'subcategory',
  initialState,
  reducers: {
    catFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    catFetchFailed(state, action) {
      return { ...state, subcategoryResponse: { status: true, message: action.payload } };
    },
    catDeleteSuccess(state) {
      return { ...state, loading: false, subcategoryDeleteSucces: true };
    },
    catDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        subcategoryDeleteResponse: { status: true, message: action.payload },
      };
    },
    catDeleteInitial(state) {
      return {
        ...state,
        subcategoryDeleteSucces: false,
        subcategoryDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  catFetchSuccess,
  catFetchFailed,
  catDeleteSuccess,
  catDeleteFailed,
  catDeleteInitial,
} = SubCategorySlice.actions;
export default SubCategorySlice.reducer;
