import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const initialState = {
  // Delete subscription
  subscriptionDeleteResponse: {
    status: false,
    message: '',
  },
  subscriptionDeleteSucces: false,
  // Delete subscription End
  error: '',
  loading: false,
};
export const subscriptionAdd = createAsyncThunk('subscription/subscriptionAdd', async (params) => {
  const rowData = {
    id: 0,
    durationInMonths: params.select,
    price: params.data.price,
    subscriptionName: params.data.name,
    // isDelete: false,
    // status: 1,
    // imageUrl: params.proof,
    imagebase64Data: `${params.proof}`,
    fileName: params.imageFilename,
  };
  const response = await fetch(
    // `https://app.osbrm.com/AddSubscriptionDetails?ImageFileName=${params.imageFilename}`,
    `https://app.osbrm.com/AddSubscriptionDetails`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(rowData),
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
// add Discount Details For Service
export const addDiscountDetailsForService = createAsyncThunk(
  'subscription/addDiscountDetailsForService',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/AddDiscountDetailsForService?ServiceID=${params.selectedService}&SubscriptionID=${params.selectedId}&discount=${params.discount.discount}&DiscountType=${params.select}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
// subscription master list
export const getDiscountDetailsForService = createAsyncThunk(
  'subscription/getDiscountDetailsForService',
  async () => {
    //
    const response = await fetch(`https://app.osbrm.com/GetDiscountDetailsForService`, {
      method: 'GET',
      headers: header,
    });
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

// discount details for subscription
export const getSubscriptionId = createAsyncThunk('subscription/getSubscriptionId', async () => {
  //
  const response = await fetch(`https://app.osbrm.com/GetSubscriptionDetails`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
// for update purpose getsubscription by id
export const getSubscriptiondetailsId = createAsyncThunk(
  'subscription/getSubscriptiondetailsId',
  async (params) => {
    //
    const response = await fetch(
      `https://app.osbrm.com/GetSubscriptionDetails?id=${params.selectedService}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
// delete subscription details
export const subscriptionDetailsDelete = createAsyncThunk(
  'subscription/subscriptionDetailsDelete',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeleteSubscriptionDetails?id=${params.deletId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
// delete discount details for services
export const subscriptionDetailsForServicesDelete = createAsyncThunk(
  'subscription/subscriptionDetailsForServicesDelete',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeleteDiscountDetailsForService?id=${params.deletId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const DeReActivateSubscriptionService = createAsyncThunk(
  'subscription/DeReActivateSubscriptionService',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeReActivateAll?action1=SubscriptionService&id=${params.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const DeReActivateSubscription = createAsyncThunk(
  'subscription/DeReActivateSubscription',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeReActivateAll?action1=Subscription&id=${params.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
// DeReActivateSubscription
export const getServiceList = createAsyncThunk('subscription/getServiceList', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Common/GetAllServices`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const GetGlobalSearchData = createAsyncThunk(
  'subscription/GetGlobalSearchData',
  async () => {
    const response = await fetch(`https://app.osbrm.com/GetGlobalSearchData`, {
      method: 'GET',
      headers: header,
    });
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
// update subscription details
export const updateSubscriptionDetails = createAsyncThunk(
  'subscription/updateSubscriptionDetails',
  async (params) => {
    const rowData = {
      id: params.subscriptionID,
      durationInMonths: params.select,
      price: params.subPrice,
      subscriptionName: `${params.subName}`,
      isDelete: false,
      status: 1,
      imageUrl: params.proof ? `${params.proof}` : '',
    };

    const response = await fetch(
      `https://app.osbrm.com/UpdateSubscriptionDetails?ImageFileName=${params.imageFilename}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rowData),
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
// update subscription master list

export const updateDiscountMasterList = createAsyncThunk(
  'subscription/updateDiscountMasterList',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/UpdateDiscountDetailsForService?id=${params.id}&ServiceID=${params.selectedService.value}&SubscriptionID=${params.selectedSubscriptionId.value}&Discount=${params.rate}&DiscountType=${params.selectedDiscountType}`,
      {
        method: 'PUT',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
// get Discount Details for services (Get discount Master List by id )
export const getDiscountMasterListById = createAsyncThunk(
  'subscription/getDiscountMasterListById',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/GetDiscountDetailsForService?id=${params}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getsubscriptionweb = createAsyncThunk('subscription/getsubscriptionweb', async () => {
  const response = await fetch('https://app.osbrm.com/api/Common/GetAllsubscription', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const subscriptionDelete = createAsyncThunk(
  'subscription/subscriptionDelete',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeleteSubscriptionDetails?id=${params.deletId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const subscriptionStatus = createAsyncThunk('subscription/status', async () => {
  const response = await fetch('https://app.osbrm.com/api/Common/ChangesubscriptionStatus', {
    method: 'PUT',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    FetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, subscriptionResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, subscriptionDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        subscriptionDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        subscriptionDeleteSucces: false,
        subscriptionDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const { FetchSuccess, FetchFailed, DeleteSuccess, DeleteFailed, DeleteInitial } =
  subscriptionSlice.actions;
export default subscriptionSlice.reducer;
