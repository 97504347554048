import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const initialState = {
  categoryDeleteResponse: {
    status: false,
    message: '',
  },
  categoryDeleteSucces: false,
  error: '',
  loading: false,
};
export const AddCategory = createAsyncThunk('category/AddCategory', async (params) => {
  const data = {
    categoryName: params.categoryName.categoryName,
    details: 'Image Upload',
    imagebase64Data: `${params.proof}`,
    fileName: params.imageFilename,
    categoryZoneList: [
      {
        zoneId: params.zonalSelect.value,
      },
    ],
  };
  const response = await fetch('https://app.osbrm.com/api/Common/AddCategory', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const categoryUpdate = createAsyncThunk('category/categoryUpdate', async (params) => {
  const data = {
    categoryName: params.categoryName,
    details: 'Image Upload',
    imagebase64Data: params.proof,
    fileName: params.imageFilename,
    categoryZoneList: [
      {
        zoneId: 0,
      },
    ],
  };
  const response = await fetch(
    `https://app.osbrm.com/api/Common/UpdateCategory/${params.categoryDetails.id}`,
    {
      method: 'PATCH',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(data),
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const AddCustomerQuery = createAsyncThunk('category/AddCustomerQuery', async (params) => {
  const data = {
    id: 0,
    customerId: params.customerId,
    serviceId: 0,
    technicianId: 0,
    query: params.query,
    queryRaisedDate: params.date,
    isQueryResolved: '',
    queryResDate: '',
    queryResBy: 0,
    queryAns: '',
  };

  const response = await fetch(`https://app.osbrm.com/api/Customer/AddCustomerQuery`, {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const AddCategoryExcel = createAsyncThunk(
  'category/AddCategoryExcel',
  async (params) => {

    const data = params.map((item) => ({
      categoryName: `${item.categoryname}`,
      details: `${item.details}`,
      imagebase64Data: 'aaa',
      fileName: '',
      zoneName: `${item.zone}`,
    }));

    const response = await fetch('https://app.osbrm.com/api/Excel/AddCategoryExcel', {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();

    if (res.status === 'True') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const getCategory = createAsyncThunk('category/get', async () => {
  const response = await fetch('https://app.osbrm.com/api/Common/GetAllCategory', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const DeReActivateCategory = createAsyncThunk(
  'category/DeReActivateCategory',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeReActivateAll?action1=Category&id=${params.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getNewServices = createAsyncThunk('category/getNewServices', async () => {
  const response = await fetch('https://app.osbrm.com/api/Common/GetNewServiceForNoti', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getZoneByCategoryID = createAsyncThunk(
  'category/getZoneByCategoryID',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Common/GetZoneByCategoryId?CategoryId=${params.value}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const AddZoneCategorywise = createAsyncThunk(
  'category/AddZoneCategorywise',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Common/AddZoneCategorywise?Zoneid=${params.zonalSelect.value}&CategoryId=${params.categoryId}`,
      {
        method: 'PUT',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const DeleteZoneCategory = createAsyncThunk(
  'category/DeleteZoneCategory',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Common/DeleteZoneCategory?id=${params.deletId}`,
      {
        method: 'DELETE',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getAllSubCategory = createAsyncThunk('category/get', async () => {
  const response = await fetch('https://app.osbrm.com/api/Common/GetAllSubCategory', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const categoryDelete = createAsyncThunk('category/delete', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Common/DeleteCategory?id=${params.deletId}`,
    {
      method: 'DELETE',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const categoryStatus = createAsyncThunk('category/status', async (data) => {
  const response = await fetch(
    'http://uat.prasadsolution.com/app/ProductCategory.asmx/changeMainCategoryStatus',
    {
      method: 'POST',
      headers: header,
      body: `mid=${data.statusId}&Authenticationkey=${'123456'}`,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const CategorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    catFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    catFetchFailed(state, action) {
      return { ...state, categoryResponse: { status: true, message: action.payload } };
    },
    catDeleteSuccess(state) {
      return { ...state, loading: false, categoryDeleteSucces: true };
    },
    catDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        categoryDeleteResponse: { status: true, message: action.payload },
      };
    },
    catDeleteInitial(state) {
      return {
        ...state,
        categoryDeleteSucces: false,
        categoryDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  catFetchSuccess,
  catFetchFailed,
  catDeleteSuccess,
  catDeleteFailed,
  catDeleteInitial,
} = CategorySlice.actions;
export default CategorySlice.reducer;
