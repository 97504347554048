import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  aepsSuccess: {
    status: false,
    message: '',
  },
  aepsCustomer: false,
  error: '',
  loading: false,
  aepsSuccessReponse: {
    status: false,
    message: '',
  },
};
export const GetUnAssignedOrdersForProffessional = createAsyncThunk(
  'technicianLeadSlice/GetUnAssignedOrdersForProffessional',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/GetUnassignedOrdersForProffessional?ProffessionalMobileNo=${params.currentUser.Phone}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const DeleteCustomerQuery = createAsyncThunk(
  'technicianLeadSlice/DeleteCustomerQuery',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Customer/DeleteCustomerQuery?Id=${params}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const ResponseCustomerQuery = createAsyncThunk('technicianLeadSlice/ResponseCustomerQuery', async (params) => {
  const data = {
    id: 0,
    customerId: 0,
    serviceId: 0,
    technicianId: 0,
    query: '',
    queryRaisedDate: '',
    isQueryResolved: '',
    queryResDate: params.date,
    queryResBy: 0,
    queryAns: params.answer,
  };
  const response = await fetch(`https://app.osbrm.com/api/Customer/ResponseCustomerQuery?Id=${params.queryId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const GetCustomerQueryies = createAsyncThunk(
  'technicianLeadSlice/GetCustomerQueryies',
  async () => {
    const response = await fetch(`https://app.osbrm.com/api/Customer/GetCustomerQueryies`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetAssignedOrdersForProffessional = createAsyncThunk(
  'technicianLeadSlice/GetAssignedOrdersForProffessional',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/GetAssignedOrdersForProffessional?ProffessionalMobileNo=${params.currentUser.Phone}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetRejectedOrdersForProffessional = createAsyncThunk(
  'technicianLeadSlice/GetRejectedOrdersForProffessional',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/GetRejectedOrdersForProffessional?ProffessionalMobileNo=${params.currentUser.Phone}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const completeOrder = createAsyncThunk(
  'technicianLeadSlice/completeOrder',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/CompleteByProfessionalOrCustRating?Action=${params.Action}&OrderId=${params.orderId}&ServiceId=${params.serviceId}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetTechSalarySlip = createAsyncThunk(
  'technicianLeadSlice/GetTechSalarySlip',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Account/GetEmpSalarySlip?id=${params.user}&date=${params.parsingdate}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const AssignOrdersToMyself = createAsyncThunk(
  'technicianLeadSlice/AssignOrdersToMyself',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/AssignOrderToMyself?id=${params.orderId}&TechnicianMobileNo=${params.mobileNo}&RejFlag=${params.Action}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const ChangeMyStatus = createAsyncThunk(
  'technicianLeadSlice/ChangeMyStatus',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/ChangeMystatus?value=${params.active}&ProffessionalMobileNo=${params.currentUser.Phone}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const technicianLeadSlice = createSlice({
  name: 'technicianLeadSlice',
  initialState,
  reducers: {
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, aepsResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, aepsCustomer: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        aepsSuccess: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        aepsCustomer: false,
        aepsSuccess: { status: false, message: '' },
      };
    },
  },
});
export const { FetchSuccess, FetchFailed, DeleteSuccess, DeleteFailed, DeleteInitial } =
  technicianLeadSlice.actions;
export default technicianLeadSlice.reducer;
