import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const initialState = {
  markSliceDeleteResponse: {
    status: false,
    message: '',
  },
  updateResponse: false,
  error: '',
  loading: false,
};
/* const data ={
  "homeScreenAds": {
    "id": 0,
    "description": params.Data.AdvsName,
    "discountDesc": params.Data.AdvsDiscount,
    "imageUrl": params.advertiseImage,
    "imageName": params.imageFilename,
    "advType": 'CO',
    "createDate": '2023-08-03T07:56:30.270Z',
    "addedBy": params.currentUser.Id,
    "addedByRole": 'MR',
    "approveCO_Status": "",
    "rejectCO_status": "",
    "status": 0
  },
   couponsOffers: {
      id: 0,
      couponId: 0,
      serviceId: params.selectedService ,
      discountType: params.selectedDiscountType ,
      discount:  params.discount ,
      couponCode: '',
    },
} */
export const addCoupons = createAsyncThunk('markSlice/addCoupons', async (params) => {
  const data = {
    homeScreenAds: {
      id: 0,
      description: params.Data.AdvsName,
      discountDesc: params.Data.AdvsDiscount,
      imageUrl: params.advertiseImage,
      imageName: params.imageFilename,
      advType: 'CO',
      createDate: '2023-08-03T07:56:30.270Z',
      addedBy: params.currentUser.Id,
      addedByRole: 'MR',
      approveCO_Status: '',
      rejectCO_status: '',
      status: 0,
    },
    couponsOffers: {
      id: 0,
      couponId: 0,
      serviceId: params.selectedService,
      discountType: params.selectedDiscountType,
      discount: params.discount,
      couponCode: '',
    },
  };
  const response = await fetch('https://app.osbrm.com/HomeScreenAdvsInsert', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const updateCouponDetails = createAsyncThunk(
  'markSlice/updateCouponDetails',
  async (params) => {
    const rowData = {
      homeScreenAds: {
        id: params.id,
        description: params.advsName,
        discountDesc: params.advsDiscount,
        imageUrl: params.advertiseImage,
        imageName: params.imageFilename,
        advType: 'CO',
        createDate: '2023-08-03T07:56:30.270Z',
        addedBy: params.currentUser.Id,
        addedByRole: 'MR',
        approveCO_Status: '',
        rejectCO_status: '',
      },
      couponsOffers: {
        id: params.couponCodeId,
        couponId: params.couponId,
        serviceId: params.selectedService.value,
        discountType: params.selectedDiscountType.value,
        discount: params.discount,
        couponCode: '',
      },
    };
    const response = await fetch(`https://app.osbrm.com/api/Marketing/UpdateCouponsOffers`, {
      method: 'PUT',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(rowData),
    });
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getCouponsForMR = createAsyncThunk('markSlice/getCouponsForMR', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Marketing/GetCouponsOffersMR?Action=${params}`,
    {
      method: 'GET',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const GetAdvertisementExcel = createAsyncThunk('markSlice/GetAdvertisementExcel', async () => {
  const response = await fetch(
    `https://app.osbrm.com/GetAdvertisementExcel`,
    {
      method: 'GET',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const GetCouponsDetails = createAsyncThunk('markSlice/GetCouponsDetails', async (params) => {
  const response = await fetch(`https://app.osbrm.com/GetCouponsDetails?id=${params}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const DeReActivateAdvs = createAsyncThunk('markSlice/DeReActivateAdvs', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/DeReActivateAll?action1=HomeScreenAdvs&id=${params.id}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
//// packages
export const addPackages = createAsyncThunk('markSlice/addPackages', async (params) => {
  const data = {
    id: 0,
    pkgName: params.Data.PackageName,
    pkgDesc: params.Data.PackageDiscount,
    expiredStatus: '',
    pkgImageName: params.imageFilename,
    pkgImageUrl: params.advertiseImage,
    serviceList: params.selectedServices,
  };

  const response = await fetch('https://app.osbrm.com/api/Marketing/AddSpecialPackages', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const updatePackages = createAsyncThunk('markSlice/updatePackages', async (params) => {
  const data = {
    id: params.packageId,
    pkgName: params.packageTitle,
    pkgDesc: params.packageDesc,
    expiredStatus: '',
    pkgImageName: params.imageFilename,
    pkgImageUrl: params.advertiseImage ? params.advertiseImage : '',
    serviceList: [],
  };

  const response = await fetch('https://app.osbrm.com/api/Marketing/UpdateSpecialPackages', {
    method: 'PUT',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const deletePackage = createAsyncThunk('markSlice/deletePackage', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Marketing/ExpirePackage?Id=${params.deleteId}&ExpFlag=Y`,
    {
      method: 'PUT',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getAllPackages = createAsyncThunk('markSlice/getAllPackages', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Marketing/GetSpecialPackages`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getAllPackagesByPkgId = createAsyncThunk(
  'markSlice/getAllPackages',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Marketing/GetSpecialPackages?PkgId=${params}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const addServicesInPackage = createAsyncThunk(
  'markSlice/addServicesInPackage',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Marketing/AddSPackagesService?ServiceId=${params.selectedServices}&PkgId=${params.packageId}`,
      {
        method: 'POST',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deleteServicesFromPackage = createAsyncThunk(
  'markSlice/deleteServicesFromPackage',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Marketing/DeletePackageServices?Id=${params.deleteId}`,
      {
        method: 'PUT',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
/// packages Api
export const couponsAcceptRejectadmin = createAsyncThunk(
  'markSlice/getCouponsForMR',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Marketing/CouponsAppRejAdmin?id=${params.deletId}&AppRej=${params.acceptStatus}`,
      {
        method: 'PUT',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const advsDelete = createAsyncThunk('markSlice/advsDelete', async (params) => {
  const response = await fetch(`https://app.osbrm.com/HomeScreenAdvsDelete?id=${params.deletId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const getAllCandidatesByAction = createAsyncThunk(
  'markSlice/getAllCandidatesByAction',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Hr/GetCandidatesByAction?Action=${params}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deleteCandidate = createAsyncThunk('markSlice/delete', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Hr/DeleteCandidates?id=${params.deletId}`,
    {
      method: 'DELETE',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const changeRecruitingStatus = createAsyncThunk(
  'markSlice/changeRecruitingStatus',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Hr/RecruitmentProcess?Action=${params.action}&AccFlag=${params.AccFlag}&id=${params.id}`,
      {
        method: 'PUT',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const getAllVendors = createAsyncThunk('markSlice/getAllVendors', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetAllVendor`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getVendorDetails = createAsyncThunk('markSlice/getVendorDetails', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetVendorByID/${params.value}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const GetUnAssignedOrdersForProffessional = createAsyncThunk(
  'markSlice/GetAssignedOrdersForProffessional',
  async () => {
    const response = await fetch(`https://app.osbrm.com/GetUnassignedOrders`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const markSlice = createSlice({
  name: 'markSlice',
  initialState,
  reducers: {
    markFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    markFetchFailed(state, action) {
      return { ...state, markSliceResponse: { status: true, message: action.payload } };
    },
    markDeleteSuccess(state) {
      return { ...state, loading: false, updateResponse: true };
    },
    markDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        markSliceDeleteResponse: { status: true, message: action.payload },
      };
    },
    markDeleteInitial(state) {
      return {
        ...state,
        updateResponse: false,
        markSliceDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  markFetchSuccess,
  markFetchFailed,
  markDeleteSuccess,
  markDeleteFailed,
  markDeleteInitial,
} = markSlice.actions;
export default markSlice.reducer;
