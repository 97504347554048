import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  aepsSuccess: {
    status: false,
    message: '',
  },
  orderSuccess: false,
  error: '',
  loading: false,
  aepsSuccessReponse: {
    status: false,
    message: '',
  },
};
export const addCartItem = createAsyncThunk('webservice/addCartItem', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Cart/AddCart?MobileNo=${params.userDetails.MobileNo}&ServiceId=${params.cartData.id}&SchedulingDateTime=${params.dateTime}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const removeAllcartItem = createAsyncThunk(
  'webservice/removeAllcartItem',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Cart/ClearCart?MobileNo=${params.userDetails.MobileNo}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const updateWalletBalance = createAsyncThunk(
  'webservice/updateWalletBalance',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/UpdateCustomerWalletBalance?Amount=${params.amount}&MobileNo=${params.userDetails.MobileNo}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getUserCartCount = createAsyncThunk('webservice/getUserCartCount', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Cart/GetCartDetailsByCustomerId?MobileNo=${params.userDetails.MobileNo}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }

  return res;
});
export const increasedCarditem = createAsyncThunk(
  'webservice/increasedCarditem',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Cart/ChangeQuantityforCart?flag=${'Increment'}&CustomerMobileNo=${
        params.userDetails.MobileNo
      }&ServiceId=${params.data.ServiceId}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const decremetCart = createAsyncThunk('webservice/decremetCart', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Cart/ChangeQuantityforCart?flag=${'Increament'}&CustomerMobileNo=${
      params.userDetails.MobileNo
    }&ServiceId=${params.data.ServiceId}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const deleteCartItem = createAsyncThunk('webservice/deleteCartItem', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Cart/ClearCartByService?MobileNo=${params.userDetails.MobileNo}&ServiceId=${params.data.ServiceId}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getUserWalletBalance = createAsyncThunk(
  'webservice/getUserWalletBalance',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/GetCustomerWalletBalance?CustomerMobileNo=${params.userDetails.MobileNo}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetAddressForCustomerWhilePlacingAddress = createAsyncThunk(
  'orderSlice/GetAddressForCustomerWhilePlacingAddress',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Order/GetAddressForCustomerWhilePlacingAddress?CustomerMobileNo=${params.userDetails.MobileNo}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const AddAddressForCustomer = createAsyncThunk(
  'orderSlice/AddAddressForCustomer',
  async (params) => {
    const rowData = {
      adress: params.address,
      pinCode: params.selectPin.label,
      city: params.selectedCity.label,
      state: params.selectedState.label,
      country: 'India',
      receiverName: params.fields.FirstName,
      receiverMobileNo: params.fields.mobileNumber,
    };
    const response = await fetch(
      `https://app.osbrm.com/api/Order/AddAddressForCustomer?MobileNo=${params.userDetails.MobileNo}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rowData),
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const DeleteAddressForCustomer = createAsyncThunk(
  'orderSlice/DeleteAddressForCustomer',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Order/DeleteAddressForCustomer?id=${params.data.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const setDefaultAddress = createAsyncThunk(
  'orderSlice/setDefaultAddress',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Order/MakeAddressDefault?id=${params.body.addressID.id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const getOrderCheckOut = createAsyncThunk('orderSlice/getOrderCheckOut', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Order/OrderCheckOut?CustomerMobileNo=${params.userDetails.MobileNo}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getOrderCheckOutForCouponCode = createAsyncThunk('orderSlice/getOrderCheckOutForCouponCode', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Order/OrderCheckOut?CustomerMobileNo=${params.userDetails.MobileNo}&CouponCode=${params.couponcode}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
// rating review
export const getreview = createAsyncThunk('orderSlice/getreview', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Order/GetRatingReview?Action=ByService&id=${params.serviceId}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const getAddressDataForOrder = createAsyncThunk(
  'orderSlice/getAddressDataForOrder',
  async (params) => {
   
    const action = params.Action;
    const subcategoryId = params.subCategory;
    const stateId = params.selectedStateValue
      ? params.selectedStateValue
      : params.e
      ? params.e.value
      : 0;
    const cityId = params.selectedStateValue
      ? params.selectedCityValue
        ? params.selectedCityValue
        : params.e
        ? params.e.value
        : 0
      : 0;
    const ZoneId = params.selectedStateValue
      ? params.selectedCityValue
        ? params.zone
          ? params.zone.value
          : params.e
          ?params.e.value
          : 0
        : 0
      : 0;
    const response = await fetch(
      `https://app.osbrm.com/api/Order/GetZoneWiseDropDownForAddAdress?Action=${action}&SubCategoryIds=${subcategoryId}&StateId=${stateId}&CityId=${cityId}&ZoneId=${ZoneId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const placedOrder = createAsyncThunk('orderSlice/placedOrder', async (params) => {
  const tip = params.selectedTip ? params.selectedTip : 0;
  const rowData = {
    status: 'order created',
    status_update_date: '2023-06-24',
    orderType: 'normal',
    paymentType: params.paymentType,
    placeAddress: params.defaultAddress.Address,
    customerName: params.defaultAddress.name,
    city: params.defaultAddress.City,
    state: params.defaultAddress.State,
    pinCode: params.defaultAddress.pincode,
    country: params.defaultAddress.Country,
  };

 
  const response = await fetch(
    `https://app.osbrm.com/api/Order/OrderPlacedNew?CustomerMobileNo=${params.userDetails.MobileNo}&Tip=${tip}&ApiName=${params.paymentMode ? params.paymentMode : 'aaa'}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rowData),
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const OrderPlacedCheckPAymentGate = createAsyncThunk(
  'orderSlice/OrderPlacedCheckPAymentGate',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Order/OrderPlacedCheckPAymentGate?orderID=${params}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const GetOrdersForCustomer = createAsyncThunk(
  'orderSlice/GetOrdersForCustomer',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Customer/GetOrdersForCustomer?CustomerMobileNo=${params.userDetails.MobileNo}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const cancelOrderCustomer = createAsyncThunk(
  'orderSlice/GetOrdersForCustomer',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Order/OrderCancellation?OrderId=${params.data.Order_Id}&CustomerMobileNo=${params.userDetails.MobileNo}&ServiceId=${params.data.serviceId}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetcancelOrderForCustomer = createAsyncThunk(
  'orderSlice/GetOrdersForCustomer',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Customer/GetCancelledOrdersForCustomer?CustomerMobileNo=${params.userDetails.MobileNo}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const OrderReschedule = createAsyncThunk('orderSlice/OrderReschedule', async (params) => {
  // const text1 = params.localDatetime
  // const text2 = ':37.007'
  // const concate=`${text1}${text2}`

  const response = await fetch(
    `https://app.osbrm.com/api/Order/OrderReschedule?RescheduleDate=${params.localDatetime}&OrderId=${params.reOrderData.Order_Id}&ServiceId=${params.reOrderData.serviceId}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getInvoiceDetails = createAsyncThunk('orderSlice/OrderReschedule', async (params) => {

  const response = await fetch(
    `https://app.osbrm.com/api/Order/OrderInvoice?OrderId=${params.orderID}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);

  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getWalletHistoryCustomer = createAsyncThunk(
  'orderSlice/getWalletHistoryCustomer',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/GetWalletHistoryForCustomer?CustomerMobileNo=${params.userDetails.MobileNo}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const completeOrderOrRateOrder = createAsyncThunk(
  'orderSlice/completeOrderOrRateOrder',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/CompleteByProfessionalOrCustRating?Action=${params.Action}&OrderId=${params.orderId}&ServiceId=${params.serviceId}&Rating=${params.ratingByCustomer}&Review=${params.comment}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getRefferalHistoryCustomer = createAsyncThunk(
  'orderSlice/getWalletHistoryCustomer',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/GetReferralHistoryForCustomer?CustomerMobileNo=${params.userDetails.MobileNo}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getSubscriptionHistoyHistoryCustomer = createAsyncThunk(
  'orderSlice/getSubscriptionHistoyHistoryCustomer',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/GetSubscriptionHistoryForCustomer?CustomerMobileNo=${params.userDetails.MobileNo}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getSubscriptionPlanDetails = createAsyncThunk(
  'orderSlice/getSubscriptionHistoyHistoryCustomer',
  async () => {
    const response = await fetch(
      `https://app.osbrm.com/GetSubscriptionBySubscriptionID?SubscriptionID=${''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getSubscriptionPlanByUser = createAsyncThunk(
  'orderSlice/getSubscriptionHistoyHistoryCustomer',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Customer/TakeSubscription?CustomerMobileNo=${params.userDetails.MobileNo}&SubscriptionID=${params.modalData.SubscriptionId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, orderSuccess: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, orderSuccess: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        aepsSuccess: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        orderSuccess: false,
        aepsSuccess: { status: false, message: '' },
      };
    },
  },
});
export const { FetchSuccess, FetchFailed, DeleteSuccess, DeleteFailed, DeleteInitial } =
  orderSlice.actions;
export default orderSlice.reducer;
