import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';

import { store } from './store/Store';
import App from './App';
// import AppLogout from './components/common/AppLogout';

const container = document.getElementById('root');
const root = createRoot(container);
// const isNewTab = sessionStorage.getItem('isNewTab');
// if (!isNewTab) {
//   // This is the first tab, set a session storage flag
//   sessionStorage.setItem('isNewTab', 'true');
//   // Clear session storage when the tab is closed
//   window.onbeforeunload = () => {
//     sessionStorage.removeItem('isNewTab');
//   };
// }

// Add a listener for the window's popstate event to prevent back navigation
// When the user lands on the page, set the flag to allow navigation
// sessionStorage.setItem('allowNavigation', 'true');
// const allowNavigation = sessionStorage.getItem('allowNavigation');
// console.log(allowNavigation);

// if(window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD){
//   window.addEventListener('popstate', () => {
//     window.history.forward();
//   })
// }
// Add a listener for the popstate event
// window.addEventListener('popstate', () => {
//   window.history.forward();
// });

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ToastContainer
        theme="colored"
        autoClose={2000}
        bodyClassName="toastBody"
        position="bottom-right"
      />
      <App />
    </BrowserRouter>
  </Provider>,
);

reportWebVitals();
