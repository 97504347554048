import { lazy } from 'react';
import Loadable from '../layouts/loader/Loadable';
import ProtectRoute from '../components/ProtectRoute';
import ProtectRouteTech from '../components/ProtectRouteTech';
import ProtectRouteHR from '../components/ProtectRouteHR';
import ProtectRouteVendor from '../components/ProtectRouteVendor';
import ProtectRouteInventory from '../components/ProtectRouteInventory';
import ProtectRouteMarketing from '../components/ProtectRouteMarketing';
import ProtectRouteAccountant from '../components/ProtectRouteAccountant';
/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
const AdminLayout = Loadable(lazy(() => import('../layouts/AdminLayout')));
const TechnicianLayout = Loadable(lazy(() => import('../layouts/TechnicianLayout')));
const HRLayout = Loadable(lazy(() => import('../layouts/HRLayout')));
const InventoryLayout = Loadable(lazy(() => import('../layouts/InventoryLayout')));
const VendorLayout = Loadable(lazy(() => import('../layouts/VendorLayout')));
const MarketingLayout = Loadable(lazy(() => import('../layouts/MarketingLayout')));
const AccountantLayout = Loadable(lazy(() => import('../layouts/AccountantLayout')));

// **** Dashboard *** //
const AdminDashboard = Loadable(lazy(() => import('../views/Dashboard/AdminDashboard')));
const CallDashboard = Loadable(lazy(() => import('../views/Dashboard/CallDashboard')));
const TechnicianDashboard = Loadable(lazy(() => import('../views/Dashboard/TechnicianDashboard')));
const VendorDashboard = Loadable(lazy(() => import('../views/Dashboard/VendorDashboard')));
const Hrdashboard = Loadable(lazy(() => import('../views/Dashboard/HrDashboard')));
const AccountDashboard = Loadable(lazy(() => import('../views/Dashboard/AccountDashboard')));
const MarketingDashboard = Loadable(lazy(() => import('../views/Dashboard/MarketingDashboard')));
const InventoryDashboard = Loadable(
  lazy(() => import('../views/Dashboard/InventoryManagerDashBoard')),
);
const ChangPasswordForProfessional = Loadable(
  lazy(() => import('../views/Dashboard/ChangPasswordForProfessional')),
);
/***** Admin Pages ****/
const EmployeeAdd = Loadable(lazy(() => import('../views/admin/Employee/EmployeeAdd')));
const EmployeeList = Loadable(lazy(() => import('../views/admin/Employee/EmployeeList')));
const CandidateListHR = Loadable(lazy(() => import('../views/admin/Employee/CandidateListHR')));
const RejectedCandidates = Loadable(
  lazy(() => import('../views/admin/Employee/RejectedCandidates')),
);
const EmployeeUpdate = Loadable(lazy(() => import('../views/admin/Employee/EmployeeUpdate')));
const CategoryAdd = Loadable(lazy(() => import('../views/admin/Category/CategoryAdd')));
const CategoryList = Loadable(lazy(() => import('../views/admin/Category/CategoryList')));
const CategoryUpdate = Loadable(lazy(() => import('../views/admin/Category/CategoryUpdate')));
const ServicesList = Loadable(lazy(() => import('../views/admin/Services/ServicesList')));
const ServicesAdd = Loadable(lazy(() => import('../views/admin/Services/ServicesAdd')));
const ServicesUpdate = Loadable(lazy(() => import('../views/admin/Services/ServicesUpdate')));
const SpecialOffersAdd = Loadable(
  lazy(() => import('../views/admin/SpecialOffer/SpecialOffersAdd')),
);
const SubCategoryAdd = Loadable(lazy(() => import('../views/admin/SubCategory/SubCategoryAdd')));
const SubCategoryList = Loadable(lazy(() => import('../views/admin/SubCategory/SubCategoryList')));
const SubCategoryUpdate = Loadable(
  lazy(() => import('../views/admin/SubCategory/SubCategoryUpdate')),
);
const ScreenBannerAdd = Loadable(lazy(() => import('../views/admin/Banner/ScreenBannerAdd')));
const ScreenBannerList = Loadable(lazy(() => import('../views/admin/Banner/ScreenBannerList')));
const BannerList = Loadable(lazy(() => import('../views/admin/Banner/BannerList')));
const BannerAdd = Loadable(lazy(() => import('../views/admin/Banner/BannerAdd')));
const AddAdvertisement = Loadable(lazy(() => import('../views/admin/Ads/AddAdvertisement')));
const AdvertisementList = Loadable(lazy(() => import('../views/admin/Ads/AdvertisementList')));
const UpdateAdvertisement = Loadable(lazy(() => import('../views/admin/Ads/UpdateAdvertisement')));
const MarketingCoupons = Loadable(lazy(() => import('../views/admin/Ads/MarketingCoupons')));
const MRCouponsHistory = Loadable(lazy(() => import('../views/admin/Ads/MRCouponsHistory')));

const ZoneList = Loadable(lazy(() => import('../views/admin/Zone/ZoneList')));
const ZoneAdd = Loadable(lazy(() => import('../views/admin/Zone/ZoneAdd')));
const ZoneUpdate = Loadable(lazy(() => import('../views/admin/Zone/ZoneUpdate')));
const VendorAdd = Loadable(lazy(() => import('../views/admin/Vendor/VendorAdd')));
const VendorList = Loadable(lazy(() => import('../views/admin/Vendor/VendorList')));
const VendorUpdate = Loadable(lazy(() => import('../views/admin/Vendor/VendorUpdate')));
const WalletHistoryVendor = Loadable(
  lazy(() => import('../views/admin/Vendor/WalletHistoryVendor')),
);
const UpdateWalletBalanceVendor = Loadable(
  lazy(() => import('../views/admin/Vendor/UpdateWalletBalanceVendor')),
);
const SetReferralCost = Loadable(lazy(() => import('../views/admin/Referral/SetReferralCost')));
const GetReferralHistory = Loadable(
  lazy(() => import('../views/admin/Referral/GetReferralHistory')),
);
const ReferralUpdateHistory = Loadable(
  lazy(() => import('../views/admin/Referral/ReferralUpdateHistory')),
);
const RatingDetails = Loadable(lazy(() => import('../views/admin/CustomerFeedBack/RatingDetails')));
const ProductRequestForVendor = Loadable(
  lazy(() => import('../views/admin/RequestsForProducts/ProductRequestForVendor')),
);
const HistoryOfRequests = Loadable(
  lazy(() => import('../views/admin/RequestsForProducts/HistoryOfRequests')),
);
const AddBannerServices = Loadable(lazy(() => import('../views/admin/Services/AddBannerServices')));
const ListBannerServices = Loadable(
  lazy(() => import('../views/admin/Services/ListBannerServices')),
);
const AddTips = Loadable(lazy(() => import('../views/admin/Tips/AddTips')));
const TipsList = Loadable(lazy(() => import('../views/admin/Tips/TipsList')));
const UpdateTip = Loadable(lazy(() => import('../views/admin/Tips/UpdateTip')));
const AccountantWallet = Loadable(lazy(() => import('../views/admin/Accountant/AccountantWallet')));
const AddPackages = Loadable(lazy(() => import('../views/admin/Packages/AddPackages')));
const PackageList = Loadable(lazy(() => import('../views/admin/Packages/PackageList')));
const UpdatePackages = Loadable(lazy(() => import('../views/admin/Packages/UpdatePackages')));
const VendorPurchaseReport = Loadable(
  lazy(() => import('../views/admin/SalesReport/VendorPurchaseReport')),
);
const ServiceSalesReport = Loadable(
  lazy(() => import('../views/admin/SalesReport/ServiceSalesReport')),
);

// customer Order
const CustomerList = Loadable(lazy(() => import('../views/admin/Customer/CustomerList')));
const GetWalletHistoryCustomer = Loadable(
  lazy(() => import('../views/admin/Customer/GetWalletHistoryCustomer')),
);
const CustomerUpdateBalance = Loadable(
  lazy(() => import('../views/admin/Customer/CustomerUpdateBalance')),
);

// subscription
const GetSubscriptionDetails = Loadable(
  lazy(() => import('../views/admin/Subscription/GetSubscriptionDetails')),
);
const AddSubscription = Loadable(lazy(() => import('../views/admin/Subscription/AddSubscription')));
const AddDiscountDetailsForService = Loadable(
  lazy(() => import('../views/admin/Subscription/AddDiscountDetailsForService')),
);
const DiscountMasterList = Loadable(
  lazy(() => import('../views/admin/Subscription/DiscountMasterList')),
);
const UpadateSubscriptiondetails = Loadable(
  lazy(() => import('../views/admin/Subscription/UpadateSubscriptiondetails')),
);
const UpdateDiscountMasterList = Loadable(
  lazy(() => import('../views/admin/Subscription/UpdateDiscountMasterList')),
);
// ORDERS
const PendingOrders = Loadable(lazy(() => import('../views/admin/Orders/PendingOrders')));
const CancelledOrders = Loadable(lazy(() => import('../views/admin/Orders/CancelledOrders')));
const AssignOrders = Loadable(lazy(() => import('../views/admin/Orders/AssignOrders')));
const CompletedOrders = Loadable(lazy(() => import('../views/admin/Orders/CompletedOrders')));
/***** Admin End ****/
/*********Web *********/
const Ratings = Loadable(lazy(() => import('../views/web/FeedBack/Ratings')));
/***** Engineer Routes ****/
const TechnicianAdd = Loadable(lazy(() => import('../views/engineer/Technician/TechnicianAdd')));
const TechnicianList = Loadable(lazy(() => import('../views/engineer/Technician/TechnicianList')));
const InventoryAdd = Loadable(lazy(() => import('../views/engineer/Inventory/InventoryAdd')));
const InventoryList = Loadable(lazy(() => import('../views/engineer/Inventory/InventoryList')));
/***** Engineer Routes ****/

/***** Technician Routes ****/
const FeedbackList = Loadable(lazy(() => import('../views/technician/Feedback/FeedbackList')));
const IdealOrderList = Loadable(lazy(() => import('../views/technician/History/IdealOrderList')));
const AcceptedOrderList = Loadable(
  lazy(() => import('../views/technician/History/AcceptedOrderList')),
);
const RejectedOrderList = Loadable(
  lazy(() => import('../views/technician/History/RejectedOrderList')),
);
const SalarySlipTech = Loadable(
  lazy(() => import('../views/technician/SalarySlip/SalarySlipTech')),
);
const GenerateSalarySliptech = Loadable(
  lazy(() => import('../views/technician/SalarySlip/GenerateSalarySliptech')),
);
const Attendance = Loadable(lazy(() => import('../views/technician/Attendance/Attendance')));
const Queries = Loadable(lazy(() => import('../views/technician/Query/Queries')));

/***** Technician Routes ****/
/***** Vendor Routes ****/

const ListProduct = Loadable(lazy(() => import('../views/vendor/Managestore/ListProducts')));
const UpdateProduct = Loadable(
  lazy(() => import('../views/InventoryManger/ManageStore/UpdateProduct')),
);
const TechnicianTaskList = Loadable(
  lazy(() => import('../views/vendor/History/TechnicianTaskList')),
);
const ProfessionalList = Loadable(
  lazy(() => import('../views/vendor/Professional/ProfessionalList')),
);
const WalletBalanceOfVendor = Loadable(
  lazy(() => import('../views/vendor/Wallet/WalletBalanceOfVendor')),
);
const ProductCart = Loadable(lazy(() => import('../views/vendor/Managestore/ProductCart')));
const ProductCheckOut = Loadable(lazy(() => import('../views/vendor/Managestore/ProductCheckOut')));
const TrackProductRequest = Loadable(
  lazy(() => import('../views/vendor/TrackRequest/TrackProductRequest')),
);
const ProductInvoice = Loadable(lazy(() => import('../views/vendor/TrackRequest/ProductInvoice')));
const ProfessionalOrders = Loadable(
  lazy(() => import('../views/vendor/Professional/ProfessionalOrders')),
);
/***** Vendor Routes ****/
/***** Inventory Routes ****/
const AddProduct = Loadable(lazy(() => import('../views/InventoryManger/ManageStore/AddProduct')));
const InventoryProductList = Loadable(
  lazy(() => import('../views/InventoryManger/ManageStore/InventoryProductList')),
);
const ProductRequests = Loadable(
  lazy(() => import('../views/InventoryManger/Requests/ProductRequests')),
);
const RequestHistory = Loadable(
  lazy(() => import('../views/InventoryManger/Requests/RequestHistory')),
);
const ProductHistory = Loadable(
  lazy(() => import('../views/InventoryManger/ManageStore/ProductHistory')),
);
//RequestHistory
/***** Inventory Routes ****/
/***** Accounts Routes ****/
const EmployeeSalaryList = Loadable(
  lazy(() => import('../views/accounts/PayModule/EmployeeSalaryList')),
);

/***** Accounts Routes ****/

/***** HR Routes ****/
const SalaryDisbursed = Loadable(lazy(() => import('../views/hr/PayModule/SalaryDisbursed')));
const SalarySlip = Loadable(lazy(() => import('../views/hr/PayModule/SalarySlip')));
const Expensesdisbursed = Loadable(lazy(() => import('../views/hr/PayModule/Expensesdisbursed')));
const SalarayProcessManual = Loadable(
  lazy(() => import('../views/hr/PayModule/SalarayProcessManual')),
);
const Interviewed = Loadable(lazy(() => import('../views/hr/RecruitingStaff/Interviewed')));
const ScreenedList = Loadable(lazy(() => import('../views/hr/RecruitingStaff/ScreenedList')));
const FinalisedList = Loadable(lazy(() => import('../views/hr/RecruitingStaff/FinalisedList')));
const AppointedList = Loadable(lazy(() => import('../views/hr/RecruitingStaff/AppointedList')));
const MontlyEmployeeDetails = Loadable(
  lazy(() => import('../views/hr/Reports/MontlyEmployeeDetails')),
);
const AttendanceList = Loadable(lazy(() => import('../views/hr/Reports/AttendanceList')));
const MonthwiseRecruitmentList = Loadable(
  lazy(() => import('../views/hr/Reports/MonthwiseRecruitmentList')),
);
const AddCandidates = Loadable(lazy(() => import('../views/hr/RecruitingStaff/AddCandidates')));
const RecruitingCount = Loadable(lazy(() => import('../views/hr/RecruitingStaff/RecruitingCount')));
const AddDocuments = Loadable(lazy(() => import('../views/hr/RecruitingStaff/AddDocuments')));
const UpdateDocuments = Loadable(lazy(() => import('../views/hr/RecruitingStaff/UpdateDocuments')));
const DocumentView = Loadable(lazy(() => import('../views/hr/RecruitingStaff/DocumentView')));
const AddSalaryDeduction = Loadable(
  lazy(() => import('../views/hr/RecruitingStaff/AddSalaryDeduction')),
);
const ViewSalaryStructure = Loadable(
  lazy(() => import('../views/hr/RecruitingStaff/ViewSalaryStructure')),
);
const UpdateSalaryDeduction = Loadable(
  lazy(() => import('../views/hr/RecruitingStaff/UpdateSalaryDeduction')),
);
const EmployeeAddHR = Loadable(lazy(() => import('../views/admin/Employee/EmployeeAddHR')));
const RecrutingHistory = Loadable(
  lazy(() => import('../views/hr/RecruitingStaff/RecrutingHistory')),
);
const AdminRejectedCandidates = Loadable(
  lazy(() => import('../views/admin/Employee/RejectedCandidates')),
);
/***** HR Routes ****/

/***** Marketing Routes ****/
const ShareRefral = Loadable(lazy(() => import('../views/marketing/Share/ShareRefral')));
const AddCoupons = Loadable(lazy(() => import('../views/marketing/Coupons/AddCoupons')));
const CouponsList = Loadable(lazy(() => import('../views/marketing/Coupons/CouponsList')));
const UpdateCoupons = Loadable(lazy(() => import('../views/marketing/Coupons/UpdateCoupons')));
const CouponsHistory = Loadable(lazy(() => import('../views/admin/Ads/MRCouponsHistory')));
/***** Auth Pages ****/
const Login = Loadable(lazy(() => import('../views/web/Login')));
const Auth = Loadable(lazy(() => import('../views/web/Auth')));
const ForgotPassword = Loadable(lazy(() => import('../views/web/ForgotPassword')));
const Home = Loadable(lazy(() => import('../views/web/Home')));
const SubCategory = Loadable(lazy(() => import('../views/web/Category/SubCategory')));
const SubCategoryE = Loadable(lazy(() => import('../views/web/Category/SubCategoryE')));
const About = Loadable(lazy(() => import('../views/web/about/About')));
const Services = Loadable(lazy(() => import('../views/web/services/services')));
const AllServices = Loadable(lazy(() => import('../views/web/services/AllServices')));
const ServicesDetails = Loadable(lazy(() => import('../views/web/Category/ServicesDetails')));
const CartPage = Loadable(lazy(() => import('../views/web/cart/cartPage')));
const UserCart = Loadable(lazy(() => import('../views/web/cart/userCart')));
const PayProcessInvoice = Loadable(lazy(() => import('../views/web/Process/PayProcessInvoice')));
const Checkout = Loadable(lazy(() => import('../views/web/accounts/Checkout')));
const SignUp = Loadable(lazy(() => import('../views/web/SignUp/SignUp')));
const MyAccounts = Loadable(lazy(() => import('../views/web/accounts/MyAccounts')));
const SubscriptionPlan = Loadable(lazy(() => import('../views/web/Subscription/SubscriptionPlan')));
const PrivacyPolicy = Loadable(lazy(() => import('../views/web/PrivacyPolicy')));
const Coupons = Loadable(lazy(() => import('../views/web/Coupons/Coupons')));
const NewLaunch = Loadable(lazy(() => import('../views/web/NewLaunch/NewLaunch')));
const CustomerServices = Loadable(
  lazy(() => import('../views/web/CustomerServices/CustomerServices')),
);
const HomeImprovement = Loadable(
  lazy(() => import('../views/web/HomeImprovement/HomeImprovement')),
);
const Notification = Loadable(lazy(() => import('../views/web/Notifications/Notification')));
/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/', name: 'home', element: <Home /> },
      { path: '/Admin', exact: true, element: <Login /> },
      { path: '/Auth', exact: true, element: <Auth /> },
      { path: '/About', exact: true, element: <About /> },
      { path: '/Services', exact: true, element: <Services /> },
      { path: '/allServices', exact: true, element: <AllServices /> },
      { path: '/SubCategory', exact: true, element: <SubCategory /> },
      { path: '/Coupons', exact: true, element: <Coupons /> },
      { path: '/NewLaunch', exact: true, element: <NewLaunch /> },
      { path: '/CustomerServices', exact: true, element: <CustomerServices /> },
      { path: '/HomeImprovement', exact: true, element: <HomeImprovement /> },

      ///CustomerServices
      { path: '/SubCategoryE', exact: true, element: <SubCategoryE /> },
      { path: '/ForgotPassword', exact: true, element: <ForgotPassword /> },
      { path: '/ServicesDetails', exact: true, element: <ServicesDetails /> },
      { path: '/cart', element: <CartPage /> },
      { path: '/UserCart', element: <UserCart /> },
      { path: '/PayProcessInvoice', element: <PayProcessInvoice /> },
      { path: '/SignUp', element: <SignUp /> },
      { path: '/Checkout', element: <Checkout /> },
      { path: '/MyAccounts', element: <MyAccounts /> },
      { path: '/PrivacyPolicy', element: <PrivacyPolicy /> },
      { path: '/SubscriptionPlan', element: <SubscriptionPlan /> },
      { path: '/Notification', element: <Notification /> },
    ],
  },
  {
    path: '/',
    element: <ProtectRoute Component={AdminLayout} />,
    children: [
      {
        path: '/Dashboard/AdminDashboard',
        name: 'AdminDashboard',
        element: <AdminDashboard />,
      },
      // Admin Routes
      { path: '/Employee/EmployeeAdd', name: 'Employee List', element: <EmployeeAdd /> },
      {
        path: 'Employee/RejectedCandidates',
        name: 'Rejected Candidates List',
        element: <RejectedCandidates />,
      },
      { path: '/category/categoryadd', name: 'Category Add', element: <CategoryAdd /> },
      { path: '/category/categorylist', name: 'Category List', element: <CategoryList /> },
      { path: '/category/categoryupdate', name: 'Category List', element: <CategoryUpdate /> },
      { path: '/subcategory/subcategoryadd', name: 'Category Add', element: <SubCategoryAdd /> },
      { path: '/subcategory/subcategorylist', name: 'Category List', element: <SubCategoryList /> },
      {
        path: '/subcategory/subcategoryUpdate',
        name: 'Category Update',
        element: <SubCategoryUpdate />,
      },
      { path: '/banner/bannerlist', name: 'Banne list', element: <BannerList /> },
      { path: '/banner/banneradd', name: 'Banne Add', element: <BannerAdd /> },
      { path: '/Banner/ScreenBannerAdd', name: 'Banne Add', element: <ScreenBannerAdd /> },
      { path: '/Banner/ScreenBannerList', name: 'Banne Add', element: <ScreenBannerList /> },
      { path: '/services/serviceslist', name: 'Service list', element: <ServicesList /> },
      { path: '/services/servicesadd', name: 'Service Add', element: <ServicesAdd /> },
      { path: '/Services/ServicesUpdate', name: 'Service Update', element: <ServicesUpdate /> },
      { path: '/Vendor/VendorAdd', name: 'Vendor Add', element: <VendorAdd /> },
      { path: '/Vendor/VendorList', name: 'Service Update', element: <VendorList /> },
      { path: '/Vendor/VendorUpdate', name: 'Service Update', element: <VendorUpdate /> },
      {
        path: '/Vendor/VendorWalletHistory',
        name: 'Wallet History Of Vendor',
        element: <WalletHistoryVendor />,
      },
      {
        path: '/Vendor/UpdateWalletBalanceVendor',
        name: 'Update Wallet Of Vendor',
        element: <UpdateWalletBalanceVendor />,
      },
      {
        path: '/CustomerFeedBack/RatingDetails',
        name: 'Rating Details',
        element: <RatingDetails />,
      },
      {
        path: '/RequestsForProducts/ProductRequestForVendor',
        name: 'Product Requests From vendor',
        element: <ProductRequestForVendor />,
      },
      {
        path: '/RequestsForProducts/HistoryOfRequests',
        name: 'Request History',
        element: <HistoryOfRequests />,
      },
      {
        path: '/Advertisement/AddAdvertisement',
        name: 'AddAdvertisement',
        element: <AddAdvertisement />,
      },
      {
        path: '/Advertisement/AdvertisementList',
        name: 'AdvertisementList',
        element: <AdvertisementList />,
      },
      {
        path: '/Advertisement/UpdateAdvertisement',
        name: 'UpdateAdvertisement',
        element: <UpdateAdvertisement />,
      },
      {
        path: '/Advertisement/MarketingCoupons',
        name: 'Marketing Coupons',
        element: <MarketingCoupons />,
      },
      {
        path: '/Advertisement/MarketingCouponsHistory',
        name: 'Marketing Coupons History',
        element: <MRCouponsHistory />,
      },
      {
        path: '/Services/AddBannerServices',
        name: 'AddBannerServices',
        element: <AddBannerServices />,
      },
      {
        path: '/Services/ListBannerServices',
        name: 'ListBannerServices',
        element: <ListBannerServices />,
      },
      { path: '/Tips/AddTips', name: 'Add Tips', element: <AddTips /> },
      { path: '/Tips/TipsList', name: 'Tips List', element: <TipsList /> },
      { path: '/Tips/UpdateTip', name: 'Update Tip', element: <UpdateTip /> },
      {
        path: '/Accountant/AccountantWallet',
        name: 'Accountant Wallet',
        element: <AccountantWallet />,
      },
      { path: '/Packages/AddPackages', name: 'Add Packages', element: <AddPackages /> },
      { path: '/Packages/PackageList', name: 'Packages List', element: <PackageList /> },
      { path: '/Packages/UpdatePackages', name: 'Update Packages', element: <UpdatePackages /> },
      {
        path: '/SalesReport/ServiceSalesReport',
        name: 'Service Sales Report',
        element: <ServiceSalesReport />,
      },
      {
        path: '/SalesReport/VendorPurchaseReport',
        name: 'Service Sales Report',
        element: <VendorPurchaseReport />,
      },
      //
      // web routes
      { path: 'FeedBack/Ratings', name: 'Ratings', element: <Ratings /> },
      // orders
      { path: '/Orders/PendingOrders', name: 'Service Update', element: <PendingOrders /> },
      { path: '/Orders/CancelledOrders', name: 'Orders', element: <CancelledOrders /> },
      { path: '/Orders/AssignOrders', name: 'Assign Orders', element: <AssignOrders /> },
      { path: '/Orders/CompletedOrders', name: 'Completed Orders', element: <CompletedOrders /> },

      {
        path: '/Customer/CustomerUpdateBalance',
        name: 'Balance Update',
        element: <CustomerUpdateBalance />,
      },

      { path: '/specialoffer/specialoffersadd', element: <SpecialOffersAdd /> },
      { path: '/Zone/ZoneList', name: 'Zone List', element: <ZoneList /> },
      { path: '/zone/zoneadd', name: 'Zone Add', element: <ZoneAdd /> },
      { path: '/Zone/ZoneUpdate', name: 'Zone Update', element: <ZoneUpdate /> },
      // REFRELL

      {
        path: '/Referral/GetReferralHistory',
        name: 'Zone Update',
        element: <GetReferralHistory />,
      },
      { path: '/Referral/SetReferralCost', name: 'Zone Update', element: <SetReferralCost /> },
      { path: '/Customer/CustomerList', name: 'Zone Update', element: <CustomerList /> },
      {
        path: '/Customer/GetWalletHistoryCustomer',
        name: 'Customer Wallet History',
        element: <GetWalletHistoryCustomer />,
      },
      {
        path: '/Referral/ReferralUpdateHistory',
        name: 'Referral Update History',
        element: <ReferralUpdateHistory />,
      },

      //Subscription
      {
        path: 'Subscription/GetSubscriptionDetails',
        name: 'Subscription Details',
        element: <GetSubscriptionDetails />,
      },
      {
        path: 'Subscription/AddSubscription',
        name: 'Add Subscrption',
        element: <AddSubscription />,
      },
      {
        path: 'Subscription/AddDiscountDetailsForService',
        name: 'Add Discount Details For Services',
        element: <AddDiscountDetailsForService />,
      },
      {
        path: 'Subscription/DiscountMasterList',
        name: 'Discount Master List',
        element: <DiscountMasterList />,
      },
      {
        path: 'Subscription/UpadateSubscriptiondetails',
        name: 'Update Subscrption Details',
        element: <UpadateSubscriptiondetails />,
      },
      {
        path: 'Subscription/UpdateDiscountMasterList',
        name: 'Update Discount Master List',
        element: <UpdateDiscountMasterList />,
      },
    ],
  },
  {
    path: '/',
    element: <ProtectRouteTech Component={TechnicianLayout} />,
    children: [
      {
        path: '/Dashboard/TechnicianDashboard',
        name: 'TechnicianDashboard',
        element: <TechnicianDashboard />,
      },
      {
        path: '/History/AcceptedOrderList',
        name: 'Inventory List',
        element: <AcceptedOrderList />,
      },
      {
        path: '/History/RejectedOrderList',
        name: 'Inventory List',
        element: <RejectedOrderList />,
      },
      { path: '/SalarySlip/SalarySlipTech', name: 'Salary Slip Tech', element: <SalarySlipTech /> },
      {
        path: '/SalarySlip/GenerateSalarySliptech',
        name: 'Generate SalarySlip tech',
        element: <GenerateSalarySliptech />,
      },
      { path: '/Attendance/Attendance', name: 'Attendance', element: <Attendance /> },
      { path: '/Query/Queries', name: 'Queries', element: <Queries /> },
    ],
  },
  {
    path: '/',
    element: <ProtectRouteHR Component={HRLayout} />,
    children: [
      {
        path: '/Dashboard/HrDashboard',
        name: 'HR Dashboard',
        element: <Hrdashboard />,
      },
      {
        path: '/PayModule/SalaryDisbursed',
        name: 'Salary Disbursed',
        element: <SalaryDisbursed />,
      },
      {
        path: '/PayModule/Expensesdisbursed',
        name: 'Expenses disbursed',
        element: <Expensesdisbursed />,
      },
      {
        path: '/PayModule/SalarayProcessManual',
        name: 'Salaray Process Manual',
        element: <SalarayProcessManual />,
      },
      { path: '/PayModule/SalarySlip', name: 'Salary Slip', element: <SalarySlip /> },
      { path: '/RecruitingStaff/Interviewed', name: 'Interviewed', element: <Interviewed /> },
      { path: '/RecruitingStaff/ScreenedList', name: 'ScreenedList', element: <ScreenedList /> },
      { path: '/RecruitingStaff/AppointedList', name: 'AppointedList', element: <AppointedList /> },
      { path: '/RecruitingStaff/FinalisedList', name: 'FinalisedList', element: <FinalisedList /> },
      {
        path: '/Reports/MontlyEmployeeDetails',
        name: 'MontlyEmployeeDetails',
        element: <MontlyEmployeeDetails />,
      },
      { path: '/Reports/AttendanceList', name: 'AttendanceList', element: <AttendanceList /> },
      {
        path: '/Reports/MonthwiseRecruitmentList',
        name: 'MonthwiseRecruitmentList',
        element: <MonthwiseRecruitmentList />,
      },
      { path: '/RecruitingStaff/AddCandidates', name: 'AddCandidates', element: <AddCandidates /> },
      {
        path: '/RecruitingStaff/RecruitingCount',
        name: 'Recruiting Count',
        element: <RecruitingCount />,
      },
      {
        path: '/RecruitingStaff/AddDocuments',
        name: 'Add Candidate Documents',
        element: <AddDocuments />,
      },
      {
        path: '/RecruitingStaff/UpdateDocuments',
        name: 'Update Candidate Documents',
        element: <UpdateDocuments />,
      },
      { path: '/RecruitingStaff/DocumentView', name: 'Document View', element: <DocumentView /> },
      {
        path: '/RecruitingStaff/AddSalaryDeduction',
        name: 'Add Salary Deduction',
        element: <AddSalaryDeduction />,
      },
      {
        path: '/RecruitingStaff/ViewSalaryStructure',
        name: 'View Salary Structure',
        element: <ViewSalaryStructure />,
      },
      {
        path: '/RecruitingStaff/UpdateSalaryDeduction',
        name: 'Update Salary Deduction',
        element: <UpdateSalaryDeduction />,
      },
      { path: '/Employee/EmployeeAddHR', name: 'Employee Add HR', element: <EmployeeAddHR /> },
      {
        path: '/RecruitingStaff/RecrutingHistory',
        name: 'Recruting History',
        element: <RecrutingHistory />,
      },
      {
        path: '/RecruitingStaff/RejectedCandidates',
        name: 'Recruting History',
        element: <AdminRejectedCandidates />,
      },
    ],
  },
  {
    path: '/',
    element: <ProtectRouteVendor Component={VendorLayout} />,
    children: [
      {
        path: '/Dashboard/VendorDashboard',
        name: 'Vendor Dashboard',
        element: <VendorDashboard />,
      },
      { path: '/Managestore/ListProducts', name: 'Product List', element: <ListProduct /> },
      {
        path: '/History/TechnicianTaskList',
        name: 'technician Task List',
        element: <TechnicianTaskList />,
      },
      {
        path: '/Wallet/WalletBalanceOfVendor',
        name: 'Wallet Balance Of Vendor',
        element: <WalletBalanceOfVendor />,
      },
      {
        path: '/Vender/ProfessionalList',
        name: 'Wallet Balance Of Vendor',
        element: <ProfessionalList />,
      },

      { path: 'Managestore/ProductCart', name: ' Product cart', element: <ProductCart /> },
      {
        path: '/Managestore/ProductCheckOut',
        name: 'Product Checkout',
        element: <ProductCheckOut />,
      },
      {
        path: '/TrackRequest/TrackProductRequest',
        name: 'Request Track',
        element: <TrackProductRequest />,
      },
      {
        path: '/TrackRequest/ProductInvoice',
        name: 'Invoice of Products',
        element: <ProductInvoice />,
      },
      {
        path: '/Professional/ProfessionalOrders',
        name: 'ProfessionalOrders',
        element: <ProfessionalOrders />,
      },
    ],
  },
  {
    path: '/',
    element: <ProtectRouteInventory Component={InventoryLayout} />,
    children: [
      {
        path: '/Dashboard/InventoryManagerDashBoard',
        name: 'Inventory Dashboard',
        element: <InventoryDashboard />,
      },
      { path: '/Managestore/AddProduct', name: 'Add Products', element: <AddProduct /> },
      {
        path: '/Managestore/InventoryProductList',
        name: 'Inventory Product List',
        element: <InventoryProductList />,
      },
      { path: '/Managestore/UpdateProduct', name: 'update Product', element: <UpdateProduct /> },
      { path: '/Requests/ProductRequests', name: 'Product Requests', element: <ProductRequests /> },
      {
        path: '/Requests/RequestHistory',
        name: 'Product Requests History',
        element: <RequestHistory />,
      },
      { path: '/ManageStore/ProductHistory', name: 'Product History', element: <ProductHistory /> },
    ],
  },
  {
    path: '/',
    element: <ProtectRouteAccountant Component={AccountantLayout} />,
    children: [
      {
        path: '/Dashboard/AccountDashboard',
        name: 'Accounts Dashboard',
        element: <AccountDashboard />,
      },
      {
        path: '/PayModule/EmployeeSalaryList',
        name: 'Employee Salary List ',
        element: <EmployeeSalaryList />,
      },
    ],
  },
  {
    path: '/',
    element: <ProtectRouteMarketing Component={MarketingLayout} />,
    children: [
      {
        path: '/Dashboard/MarketingDashboard',
        name: 'Marketing Dashboard',
        element: <MarketingDashboard />,
      },
      { path: '/share/sharerefral', name: 'Share Refral', element: <ShareRefral /> },
      { path: '/Coupons/AddCoupons', name: 'Add Coupons', element: <AddCoupons /> },
      { path: '/Coupons/CouponsList', name: 'Coupons List', element: <CouponsList /> },
      { path: '/Coupons/UpdateCoupons', name: 'Update Coupons ', element: <UpdateCoupons /> },
      { path: '/Coupons/CouponsHistory', name: 'Coupons History', element: <CouponsHistory /> },
    ],
  },
  {
    path: '/',
    element: <FullLayout />,
    children: [
      {
        path: '/Dashboard/CallDashboard',
        name: 'callDashboard',
        element: <CallDashboard />,
      },
      {
        path: '/Dashboard/ChangPasswordForProfessional',
        name: 'Inventory Dashboard',
        element: <ChangPasswordForProfessional />,
      },
      //
      // Admin Routes
      { path: '/Employee/Employeelist', name: 'Employee List', element: <EmployeeList /> },
      { path: 'Employee/CandidateListHR', name: 'Candidate List', element: <CandidateListHR /> },
      { path: '/employee/EmployeeUpdate', name: 'Employee List', element: <EmployeeUpdate /> },

      // Enginner Routes
      { path: '/technician/technicianadd', name: 'Technician Add', element: <TechnicianAdd /> },
      { path: '/technician/technicianlist', name: 'Technician List', element: <TechnicianList /> },
      { path: '/inventory/inventoryadd', name: 'Inventory Add', element: <InventoryAdd /> },
      { path: '/inventory/inventorylist', name: 'Inventory List', element: <InventoryList /> },

      // Technician Routes
      { path: '/feedback/feedbacklist', name: 'Inventory List', element: <FeedbackList /> },
      { path: '/History/IdealOrderList', name: 'Ideal order List', element: <IdealOrderList /> },

      // Vendor Routes


      ///ManageStore/ProductHistory

      // Inventory Routes //InventoryProductList

      // HR Routes
  

      // Accounts Routes /PayModule/EmployeeSalaryList
   

      // Marketing Routes
      //

    ],
  },
];

export default ThemeRoutes;
