import * as Icon from 'react-feather';

const SidebarTech = [
  {
    title: 'Dashboard',
    href: '/Dashboard/TechnicianDashboard',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Home />,
    id:1,
  },
  {
    title: 'History',
    // href: '/Dashboard/TechnicianDashboard',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Home />,
    collapisble: true,
    id:2,
    children: [
      // {
      //   title: 'Ideal',
      //   href: '/History/IdealOrderList',
      //   icon: <Icon.Clipboard />,
      // },
      {
        title: 'Accepted Orders',
        href: '/History/AcceptedOrderList',
        icon: <Icon.CheckCircle/>,
      },
      {
        title: 'Rejected Orders',
        href: '/History/RejectedOrderList',
        icon: <Icon.Disc/>,
      },
    ],
  },
  // {
  //   title: 'Leads',
  //   href: '/Technician/TechnicianList',
  //   icon: <Icon.Activity />,
  //   id: 3,
  // },
  {
    title: 'Salary Slip',
    href: '/SalarySlip/GenerateSalarySliptech',
    icon: <Icon.Activity />,
    id: 9,
  },
  {
    title: 'Attendance',
    href: '/Attendance/Attendance',
    icon: <Icon.User />,
    id: 4,
  },
  {
    title: 'Query',
    href: '/Query/Queries',
    icon: <Icon.MapPin />,
    id: 5,
  },
  // {
  //   title: 'Customer Details',
  //   href: '/Technician/TechnicianList',
  //   icon: <Icon.User />,
  //   id: 6,
  // },
  // {
  //   title: 'Working Status',
  //   href: '/Technician/TechnicianList',
  //   icon: <Icon.Watch />,
  //   id: 7,
  // },
  
  // {
  //   title: 'Credit Management',
  //   href: '/technician/technicianlist',
  //   icon: <Icon.CreditCard />,
  //   id: 8,
  // },
  // {
  //   title: 'Feedback',
  //   href: 'feedback/feedbacklist',
  //   icon: <Icon.Star />,
  //   id: 8,
  // },
];

export default SidebarTech;
