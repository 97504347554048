import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const initialState = {
  accSliceDeleteResponse: {
    status: false,
    message: '',
  },
  updateResponse: false,
  error: '',
  loading: false,
};

export const addCandidate = createAsyncThunk('accSlice/addCandidate', async (params) => {
  const data = {
    id: 0,
    firstName: `${params.fields.FirstName}`,
    middleName: `${params.fields.MiddleName}`,
    lastName: `${params.fields.LastName}`,
    phone: `${params.fields.mobileNumber}`,
    address: `${params.address}`,
    designationType: `${params.designation.value}`,
    offOnRole: `${params.selectedRole.value}`,
    cvFileName: `${params.imageFilename}`,
    cvFileUrl: `${params.proof}`,
    dob: `${params.fields.dob}`,
    pincode: `${params.fields.pincode}`,
    email: `${params.Email}`,
    isScreened: '',
    isInterview: '',
    isFinalised: '',
    isAppointed: '',
    totalExp: `${params.fields.experience}`,
  };
  const response = await fetch('https://app.osbrm.com/api/Hr/AddCandidates', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const addDocuments = createAsyncThunk('accSlice/addDocuments', async (params) => {
  const data = {
    candidateId: `${params.candidateId}`,
    eduDocsFileName: `${params.educationDocsFileName}`,
    eduDocsFileUrl: `${params.educationDocs}`,
    aadharFileName: `${params.adharFileName}`,
    aadharFileUrl: `${params.adharImage}`,
    panFileName: `${params.panFileName}`,
    panFileUrl: `${params.panImage}`,
    imageName: `${params.profileFileName}`,
    imageUrl: `${params.profileImage}`,
    prevOrgDocsFileName: `${params.imageFilename}`,
    prevOrgDocsFileUrl: `${params.proof}`,
    expectedSal: `${params.fields.expectedSalary}`,
    offeredSal: `${params.fields.offeredSalary}`,
    previousSal: `${params.fields.previousSalary}`,
    expectedJoinDate: `${params.fields.joiningDate}`,
    verificationSts: '',
    bankAccNo: `${params.fields.accountNo}`,
    bankIFSC: `${params.fields.IFSC}`,
  };
  const response = await fetch('https://app.osbrm.com/api/Hr/AddCandidateDocs', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const updateDocuments = createAsyncThunk('accSlice/updateDocuments', async (params) => {
  const data = {
    candidateId: `${params.candidateId}`,
    eduDocsFileName: `${params.educationDocsFileName}`,
    eduDocsFileUrl: `${params.educationDocs}`,
    aadharFileName: `${params.adharFileName}`,
    aadharFileUrl: `${params.adharImage}`,
    panFileName: `${params.panFileName}`,
    panFileUrl: `${params.panImage}`,
    imageName: `${params.profileFileName}`,
    imageUrl: `${params.profileImage}`,
    prevOrgDocsFileName: `${params.imageFilename}`,
    prevOrgDocsFileUrl: `${params.proof}`,
    expectedSal: `${params.expectedSalary}`,
    offeredSal: `${params.offeredSalary}`,
    previousSal: `${params.previousSalary}`,
    expectedJoinDate: `${params.fields.joiningDate}`,
    verificationSts: '',
  };

  const response = await fetch('https://app.osbrm.com/api/Hr/UpdateCandidateDocs', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const addRecrutingCount = createAsyncThunk('accSlice/addRecrutingCount', async (params) => {
  const data = {
    id: 0,
    accId: `${params.currentUser.Id}`,
    screeningProcess: `${params.Data.screened}`,
    interviewed: `${params.Data.interviewed}`,
    finalized: `${params.Data.finalised}`,
    appointedTech: `${params.Data.appointedTech}`,
    apointedEngg: `${params.Data.appointedEngg}`,
    createDate: `${params.localDatetime}`,
  };
  const response = await fetch('https://app.osbrm.com/api/Hr/AddIntoRecruitmentLog', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const updateProfessionalDetails = createAsyncThunk(
  'accSlice/updateProfessionalDetails',
  async (params) => {
    const fullname = params.data.firstName.concat(' ', params.data.lastName);
    const rowData = {
      fullName: fullname,
      roleId: params.data.professionRole.value,
      cityId: params.data.selectCity,
      stateId: params.data.selectState,
      pinCode: params.data.pincode,
      addressLine1: params.data.address,
      addressLine2: params.data.address,
      createdDate: '2023-07-17T10:05:50.317Z',
      phone: params.data.phoneNumber,
      email: params.data.emailAddress,
      birthDate: params.data.DOB,
      panNumber: params.data.panNumber,
      aadharNumber: params.data.adharNumber,
      vendorId: params.data.vendorID,
      professionalServicesDetailsList: [
        {
          serviceId: params.data.serviceID,
        },
      ],
    };

    const response = await fetch(
      `https://app.osbrm.com/api/Professional/UpdateProfessional?id=${params.data.userID}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
        body: JSON.stringify(rowData),
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getAllCandidates = createAsyncThunk('accSlice/getAllCandidates', async () => {
  const response = await fetch('https://app.osbrm.com/api/Hr/GetCandidatesAllOrById', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getSalaryStructure = createAsyncThunk('accSlice/getSalaryStructure', async () => {
  const response = await fetch('https://app.osbrm.com/api/Hr/GetSalaryStructure', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const addSalaryDetailsEmp = createAsyncThunk(
  'accSlice/addSalaryDetailsEmp',
  async (params) => {

    const response = await fetch(
      `https://app.osbrm.com/api/Hr/AddSalaryDetailsEmp?EmpId=${params.candidateID}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
        body: JSON.stringify(params.dataToSend),
      },
    );
    const res = await response.json();

    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const updateSalaryDetailsEmp = createAsyncThunk(
  'accSlice/updateSalaryDetailsEmp',
  async (params) => {

    const response = await fetch(
      `https://app.osbrm.com/api/Hr/UpdateSalaryDetailsEmp?EmpId=${params.candidateID}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
        body: JSON.stringify(params.dataToSend),
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
//

export const getAttendanceList = createAsyncThunk('accSlice/getAttendanceList', async (params) => {
  const month = params.selectMonth ? params.selectMonth : 0;
  const response = await fetch(`https://app.osbrm.com/api/Hr/GetAttendance?month=${month}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getCalEmployeeSal = createAsyncThunk(
  'accSlice/getCalEmployeeSal',
  async (params) => {

    const response = await fetch(
        `https://app.osbrm.com/api/Account/CalculateEmpSalary?id=${params.id}&date=${params.date}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getActiveEmployees = createAsyncThunk('accSlice/getActiveEmployees', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Account/GetEmployeeDetailsSalary`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});


export const payEmpSal = createAsyncThunk(
  'accSlice/payEmpSal',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Account/PayEmpSalary?id=${params}`,
      {
        method: 'POST',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deleteCandidate = createAsyncThunk('accSlice/delete', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Hr/DeleteCandidates?id=${params.deletId}`,
    {
      method: 'DELETE',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') { 
    return res;
  }
  return res;
});
export const changeRecruitingStatus = createAsyncThunk(
  'accSlice/changeRecruitingStatus',
  async (params) => {
    const rejectRemark = params.RejectRemark ? params.RejectRemark : '';
    const revSal = params.RevSal ? params.RevSal : 0;
    const revJDate = params.RevJoDate ? params.RevJoDate : '';
    const response = await fetch(
      `https://app.osbrm.com/api/Hr/RecruitmentProcess?Action=${params.action}&AccFlag=${params.AccFlag}&id=${params.id}&RejectRemark=${rejectRemark}&RevSal=${revSal}&RevJoDate=${revJDate}`,
      {
        method: 'PUT',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const getAllVendors = createAsyncThunk('accSlice/getAllVendors', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetAllVendor`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getVendorDetails = createAsyncThunk('accSlice/getVendorDetails', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetVendorByID/${params.value}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const GetUnAssignedOrdersForProffessional = createAsyncThunk(
  'accSlice/GetAssignedOrdersForProffessional',
  async () => {
    const response = await fetch(`https://app.osbrm.com/GetUnassignedOrders`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const accSlice = createSlice({
  name: 'accSlice',
  initialState,
  reducers: {
    accFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    accFetchFailed(state, action) {
      return { ...state, accSliceResponse: { status: true, message: action.payload } };
    },
    accDeleteSuccess(state) {
      return { ...state, loading: false, updateResponse: true };
    },
    accDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        accSliceDeleteResponse: { status: true, message: action.payload },
      };
    },
    accDeleteInitial(state) {
      return {
        ...state,
        updateResponse: false,
        accSliceDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const { accFetchSuccess, accFetchFailed, accDeleteSuccess, accDeleteFailed, accDeleteInitial } =
  accSlice.actions;
export default accSlice.reducer;
