import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const initialState = {
  // Delete zone
  zoneDeleteResponse: {
    status: false,
    message: '',
  },
  zoneDeleteSucces: false,
  // Delete zone End
  error: '',
  loading: false,
};
export const zoneAdd = createAsyncThunk('zoneSlice/ZoneAdd', async (params) => {
  const data = {
    zoneName: params.zoneName.zoneName,
    cityId: params.selectedCity.value,
    stateId: params.selectedState.value,
    pinCode: params.zoneName.pinCode,
  };

  const response = await fetch('https://app.osbrm.com/api/Common/AddZone', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const zoneAddNew = createAsyncThunk('zoneSlice/zoneAddNew', async (params) => {
  
  const data = {
    zoneName: params.zoneName.zoneName,
    cityId: params.selectedCity.value,
    stateId: params.selectedState.value,
    pinCode: params.zoneName.pinCode,
  };
  const response = await fetch('https://app.osbrm.com/api/Common/AddZoneNew', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const AddZoneExcel = createAsyncThunk('zoneSlice/AddZoneExcel', async (params) => {

  const data = params.map((item)=>(
    {
      zoneName: item.zonename,
      cityName: item.city,
      stateName: item.state,
      pinCode: `${item.pincode}`,
    }
  ));
  const response = await fetch('https://app.osbrm.com/api/Excel/AddZoneExcel', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getZonalList = createAsyncThunk('zoneSlice/getZonalList', async () => {
  const response = await fetch('https://app.osbrm.com/api/Common/GetAllZone', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getZonalListwithRespectToCity = createAsyncThunk('zoneSlice/getZonalListwithRespectToCity', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Common/GetZonesWithRepsectToCity?StateId=${params.selectedState ? params.selectedState.value : params.intialState}&CityId=${params.e.value}`, {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getStateList = createAsyncThunk('zoneSlice/getZonalList', async () => {
  const response = await fetch('https://app.osbrm.com/GetAllState', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getCityList = createAsyncThunk('zoneSlice/getCityList', async (params) => {
  const response = await fetch(`https://app.osbrm.com/GetAllCityByStateId/${params.value}`, {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const GetPincodeByZoneId = createAsyncThunk('zoneSlice/GetPincodeByZoneId', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Common/GetPincodeByZoneId?id=${params.value}`, {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const zoneDelete = createAsyncThunk('zoneSlice/zoneDelete', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Common/DeleteZone?id=${params.deletId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const pinDelete = createAsyncThunk('zoneSlice/pinDelete', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Common/DeletePincodes?id=${params.deletId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const DeReActivateZone = createAsyncThunk('zoneSlice/DeReActivateZone', async (params) => {
  const response = await fetch(`https://app.osbrm.com/DeReActivateAll?action1=Zone&id=${params.id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const AddPincodesZonewise = createAsyncThunk('zoneSlice/AddPincodesZonewise', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Common/AddPincodesZonewise?Zoneid=${params.zoneId}&Pincode=${params.fields.pinCode}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const zoneUpate = createAsyncThunk('zoneSlice/zoneUpate', async (params) => {

  const data = {
    zoneName: params.zonalName,
    cityId: params.selectedCity.value ? params.selectedCity.value : params.selectedCity,
    stateId: params.selectedState.value ? params.selectedState.value : params.selectedState,
    pinCode: '',
  };
  const response = await fetch(`https://app.osbrm.com/api/Common/UpdateZone/${params.zoneID}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getZonalDetails = createAsyncThunk('zoneSlice/zoneUpate', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Common/GetZoneByID/${params}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const zoneSlice = createSlice({
  name: 'zoneSlice',
  initialState,
  reducers: {
    FetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, zoneResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, zoneDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        zoneDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        zoneDeleteSucces: false,
        zoneDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const { FetchSuccess, FetchFailed, DeleteSuccess, DeleteFailed, DeleteInitial } =
  zoneSlice.actions;
export default zoneSlice.reducer;
