import * as Icon from 'react-feather';

const SidebarInventory = [
  {
    title: 'Dashboard',
    href: '/Dashboard/InventoryManagerDashBoard',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Home />,
    id: 1,
  },
  {
    title: 'Manage Store',
    href: '/vendor/Managestore',
    icon: <Icon.Activity />,
    id: 2,
    collapisble: true,
    children: [
      {
        title: 'Add Product',
        href: '/Managestore/AddProduct',
        icon: <Icon.Clipboard />,
      },
      {
        title: 'Product List',
        href: '/Managestore/InventoryProductList',
        icon: <Icon.CheckCircle/>,
      },
      {
        title: 'Product History',
        href: '/ManageStore/ProductHistory',
        icon: <Icon.CheckCircle/>,
      },

    ],
  },
  {
    title: 'Requests',
    href: '/Inventory/Requests',
    icon: <Icon.Activity />,
    id: 2,
    collapisble: true,
    children: [
      {
        title: 'Product Requests',
        href: '/Requests/ProductRequests',
        icon: <Icon.Clipboard />,
      },
      {
        title: 'Request History',
        href: '/Requests/RequestHistory',
        icon: <Icon.CheckCircle/>,
      },

    ],
  },
  // {
  //   title: 'Professional List',
  //   href: '*',
  //   icon: <Icon.Eye />,
  //   id: 3,
  // },
  // {
  //   title: 'History',
  //   href: '*',
  //   icon: <Icon.Eye />,
  //   id: 3,
  // },

  // {
  //   title: 'Orders',
  //   href: '*',
  //   icon: <Icon.Eye />,
  //   id: 4,
  // },
];

export default SidebarInventory;
