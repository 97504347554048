import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminLayout from '../layouts/AdminLayout';

function ProtectRoute() {

  const { user: currentUser } = useSelector((state) => state.user);
  return currentUser.Role === 'A' ? <AdminLayout /> : <Navigate to="/Admin" />;
}
export default ProtectRoute;
