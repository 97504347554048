import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const initialState = {
  InventorySliceDeleteResponse: {
    status: false,
    message: '',
  },
  updateResponse: false,
  error: '',
  loading: false,
};


export const addVendor = createAsyncThunk('vendorSlice/addVendor', async (params) => {

  const fullname = params.fields.FirstName .concat(' ',params.fields.LastName)
  const data = {
    fullName: `${fullname}`,
    cityId: `${params.selectedCity.value}`, 
    stateId: `${params.selectedState.value}`,
    pinCode: `${params.fields.pincode}`,
    addressLine1: `${params.address}`,
    addressLine2: `${params.address}`,
    createdDate: `${params.fields.dob}`,
    phone:params.fields.mobileNumber,
    email: `${params.vendorsEmail}`,
    mobileNo: params.fields.mobileNumber, 
    birthDate: `${params.fields.dob}`,
    panNumber: `${params.fields.pan_number}`,
    aadharNumber: `${params.fields.adhar_number}`,
    gstno: `${params.fields.gstNumber}`,
    password:'ketan@8308',
    aadharProof:`${params.proof}`,
    aadharFileName:`${params.imageFilename}`,
    vendorZoneList:[{
      zoneId: `${params.zonalSelect.value}`
    }],
  };

  const response = await fetch('https://app.osbrm.com/api/Vendor/AddVendor', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'True') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});


export const updateVendorDetails = createAsyncThunk(
  'vendorSlice/updateVendorDetails',
  async (params) => {
    
     const fullname = params.data.firstName.concat(' ',params.data.lastName)
    const rowData = {
      fullName:`${fullname}`,
      cityId:`${params.data.selectedCity}`,
      stateId:`${params.data.selectedState}` ,
      pinCode: `${params.data.pincode}`,
      addressLine1:`${ params.data.address}`,
      addressLine2:`${ params.data.address}`,
      createdDate: '2023-04-27T10:39:18.674Z',
      phone: `${params.data.phoneNumber}`,
      mobileNo: `${params.data.phoneNumber}`,
      email: `${params.data.emailAddress}`,
      birthDate: `${params.data.DOB}`,
      panNumber: `${params.data.panNumber}`,
      aadharNumber:`${ params.data.adharNumber}`,
      gstno:`${params.data.gstNumber}`,
      aadharProof:`${params.data.proof}`,
      aadharFileName:`${params.data.imageFilename}`,
      password:'myPassword',
      vendorZoneList: [
        {
          zoneId: `${params.data.zonalSelect}`
        }
      ]
    };

    const response = await fetch(`https://app.osbrm.com/api/Vendor/UpdateVendor/${params.userID}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(rowData),
    });
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const addProducts = createAsyncThunk('vendorSlice/addProducts', async (params) => {
  
  const data = {
    vendorId : `${params.currentUser.Id}`,
    id: '0',
    productName:params.fields.productName,
    mrp: params.price,
    discount: params.discount,
    discountType: params.select,
    actualPrice: params.price,
    productImageUrl: params.bannerImage,
    productImage:params.imageFilename,
    quantity: params.fields.quantity
  }
  const response = await fetch('https://app.osbrm.com/api/Vendor/AddProducts', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const UpdateProductDetails = createAsyncThunk('vendorSlice/UpdateProductDetails', async (params) => {
  
  const data = {
    vendorId : `${params.currentUser.Id}`,
    id: `${params.id}`,
    productName:params.productName,
    mrp: params.price,
    discount: params.discount,
    discountType: params.select,
    actualPrice: params.price,
    productImageUrl: params.bannerImage,
    productImage:params.imageFilename,
    quantity: params.quantity
  }
  
  const response = await fetch('https://app.osbrm.com/api/Vendor/UpdateProducts', {
    method: 'PUT',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const RequestProductByVendor = createAsyncThunk('vendorSlice/RequestProductByVendor', async (params) => {

  const response = await fetch(`https://app.osbrm.com/api/Vendor/RequestProductByVendor?ProductId=${params.productDetails.id}&VendorId=${params.productDetails.VendorId}&RequestQty=${params.requestQuantity}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const RequestReApprove = createAsyncThunk('vendorSlice/RequestReApprove', async (params) => {
  
  const response = await fetch(`https://app.osbrm.com/api/Vendor/RequestReApprove?ProfessionalId=${params.currentUser.Id}&IOrderId=${params.orderId}&ResRole=${params.ResRole}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getVenderProduct = createAsyncThunk('vendorSlice/addProducts', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetProductsByVendorId?vendorId=${params.currentUser.Id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
// vendor Wallet 
export const getVendorWalletHistory = createAsyncThunk('webservice/getVendorWalletHistory', async () => {
  

  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetVendorWalletHistById?id=${''}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json', 
    },

  });
    const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getRequestTrack = createAsyncThunk('inventorySlice/getRequestTrack', async () => {
 
  const response = await fetch('https://app.osbrm.com/api/Vendor/TrackRequest?Action=GetPendingRequestAckFromIM', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getProductTrack = createAsyncThunk('inventorySlice/getProductTrack', async () => {
 
  const response = await fetch('https://app.osbrm.com/api/Vendor/GetProductHistory', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getRequestTrackForAll = createAsyncThunk('inventorySlice/getRequestTrackForAll', async () => {
 
  const response = await fetch('https://app.osbrm.com/api/Vendor/TrackRequest?Action=AllRequest', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getViewRequestOrderData= createAsyncThunk('inventorySlice/getViewRequestOrderData', async (params) => {
  
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetViewRequestOrderData?IOrderId=${params}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const RequestRejectOrAccept = createAsyncThunk('inventorySlice/RequestRejectOrAccept', async (params) => {
  
  const response = await fetch(`https://app.osbrm.com/api/Vendor/RequestRejectAccept?ProfessionalId=${params.currentUser.Id}&RejFlag=${params.rejectStatus}&ResRole=IM&IOrderId=${params.deletId}`, {
    method: 'PUT',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});


// for admin to accept or reject 
export const RequestRejectOrAcceptByAdmin = createAsyncThunk('inventorySlice/RequestRejectOrAcceptByAdmin', async (params) => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/RequestRejectAccept?ProfessionalId=${params.currentUser.Id}&RejFlag=${params.rejectStatus}&ResRole=A&IOrderId=${params.deletId}`, {
    method: 'PUT',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const getRequestTrackForAdmin = createAsyncThunk('inventorySlice/getRequestTrackForAdmin', async () => {
 
  const response = await fetch('https://app.osbrm.com/api/Vendor/TrackRequest?Action=GetPendingRequestAckFromAdmin', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getProfessionalListVender = createAsyncThunk('vendorSlice/addProducts', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetProfessionalListForVendor?VendorMobileNo=${'9860609898'}`, {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const deletVenderProduct = createAsyncThunk('vendorSlice/deleteVendor', async (params) => {
  
  const response = await fetch(
    `https://app.osbrm.com/api/Vendor/DeleteProduct?id=${params.deletId}`,
    {
      method: 'POST',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const deleteVendor = createAsyncThunk('vendorSlice/deleteVendor', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Vendor/DeleteVendor?id=${params.deletId}`,
    {
      method: 'DELETE',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const addAmountToVendor = createAsyncThunk('vendorSlice/addAmountToVendor', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Vendor/AdminPayForVendorWallet?id=${params.tempMobile}&Amount=${params.tempAmount}`,
    {
      method: 'POST',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getProfessionalDetails = createAsyncThunk(
  'vendorSlice/GetProfessionalBy',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/GetProfessionalByID/${params.userID}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

// VENDORS API LIST
export const getAllVendors = createAsyncThunk('vendorSlice/getAllVendors', async () => {
  ;
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetAllVendor`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getVendorDetails = createAsyncThunk(
  'vendorSlice/getVendorDetails',
  async (params) => {
    ;
    const response = await fetch(`https://app.osbrm.com/api/Vendor/GetVendorByID/${params.userID}`, {
      method: 'GET',
      headers: header,
    });
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetUnAssignedOrdersForProffessional = createAsyncThunk(
  'technicianLeadSlice/GetAssignedOrdersForProffessional',
  async () => {
    const response = await fetch(`https://app.osbrm.com/GetUnassignedOrders`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const InventorySlice = createSlice({
  name: 'InventorySlice',
  initialState,
  reducers: {
    InvFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    InvFetchFailed(state, action) {
      return { ...state, InventorySliceResponse: { status: true, message: action.payload } };
    },
    InvDeleteSuccess(state) {
      return { ...state, loading: false, updateResponse: true };
    },
    InvDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        InventorySliceDeleteResponse: { status: true, message: action.payload },
      };
    },
    InvDeleteInitial(state) {
      return {
        ...state,
        updateResponse: false,
        InventorySliceDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  InvFetchSuccess,
  InvFetchFailed,
  InvDeleteSuccess,
  InvDeleteFailed,
  InvDeleteInitial,
} = InventorySlice.actions;
export default InventorySlice.reducer;
