import { configureStore } from '@reduxjs/toolkit';
// Admin slice
import CustomizerReducer from './customizer/CustomizerSlice';
import userReducer from "../slices/AuthSlice/userSilce"; 
import bannerReducer from "../slices/BannerSlice/BannerSlice";
import categoryReducer from "../slices/CategorySlice/CategorySlice";
import subcatReducer from "../slices/CategorySlice/SubCatSlice";
import servicesReducer from "../slices/CategorySlice/ServicesSlice";
import employeeReducer from "../slices/EmployeeSlice/employeeSlice";
import zoneReducer from "../slices/ZonalSlice/zoneSlice";
import commonReducer from "../slices/CommonSlice/commonSlice";
import adminOrderSliceReducer from "../slices/Orders/adminOrderSlice";
import subscriptionSliceReducer from '../slices/Subscription/subscriptionSlice';
import vendorReducer from '../slices/VendorSlice/vendorSlice'
// End
import webSitesReducer from "../slices/WebSlice/webSiteslice";
import cartReducer from "../slices/WebSlice/cartSlice";
import orderReducer from "../slices/WebSlice/orderSlice";
//  Techinician slice
import technicianLeadReducer from "../slices/Technician/technicianLeadSlice"
// Inventory Slice 
import inventoryReducer from '../slices/InventorySlice/inventorySlice'
//Hr Slice
import hrReducer from '../slices/HrSlice/hrSlice'
// Account Slice
import accReducer from '../slices/AccountSlice/accountSlice'
import markReducer from '../slices/Marketing/marketingSlice'



export const store = configureStore({
  reducer: {
    // AdminSlice
    customizer: CustomizerReducer,
    user: userReducer,
    banner:bannerReducer,
    category:categoryReducer,
    subcategory:subcatReducer,
    services:servicesReducer,
    employeeSlice:employeeReducer,
    zoneSlice:zoneReducer,
    commonSlice:commonReducer,
    adminOrderSlice:adminOrderSliceReducer,
    subscriptionSlice : subscriptionSliceReducer,
    vendorSlice : vendorReducer,
    hrSlice : hrReducer,
    markSlice : markReducer,
    accSlice : accReducer,
    // UserSlice
    webservice:webSitesReducer,
    allCart: cartReducer,
    orderSlice:orderReducer,

    // Technician
    technicianLeadSlice:technicianLeadReducer,
    //Inventory 
    InventorySlice:inventoryReducer,
  },
});

export default store;
