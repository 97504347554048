import * as Icon from 'react-feather';

const SidebarVendor = [
  {
    title: 'Dashboard',
    href: '/Dashboard/VendorDashboard',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Home />,
    id: 1,
  },
  {
    title: 'Store',
    href: '/vendor/Managestore',
    icon: <Icon.Activity />,
    id: 2,
    collapisble: true,
    children: [
      {
        title: 'Product List',
        href: '/Managestore/ListProducts',
        icon: <Icon.CheckCircle/>,
      },
      {
        title: 'Track Product Request',
        href: '/TrackRequest/TrackProductRequest',
        icon: <Icon.Eye />,
      }
    ],
  },
  {
    title: 'Professional List',
    href: '/Vender/Professional',
    icon: <Icon.Eye />,
    id: 3,
    collapisble: true,
    children: [
      {
        title: 'Professional List',
        href: '/Vender/ProfessionalList',
        icon: <Icon.List/>,
      },
      {
        title: 'Professional Orders',
        href: '/Professional/ProfessionalOrders',
        icon: <Icon.AlertOctagon/>,
      },
    ],
  },
  // {
  //   title: 'Track Product Request',
  //   href: '/TrackRequest/TrackProductRequest',
  //   icon: <Icon.Eye />,
  //   id: 3,
  // },

  // {
  //   title: 'Orders',
  //   href: '/technician/technicianlist',
  //   icon: <Icon.Eye />,
  //   id: 4,
  // },
];

export default SidebarVendor;
