import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  zoneDeleteResponse: {
    status: false,
    message: '',
  },
  zoneDeleteSucces: false,
  error: '',
  loading: false,
};

export const getStateList = createAsyncThunk('commonSlice/getZonalList', async () => {
  const response = await fetch('https://app.osbrm.com/GetAllState', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getCashFreeSesssion = createAsyncThunk(
  'commonSlice/getCashFreeSesssion',
  async (params) => {
    const amount = Number(params.walletAmount);
    const data = {
      customer_id: params.user ? `${params.currentUser.Id}` : `${params.userDetails.id}`,
      customer_phone: params.user ? `${params.currentUser.MobileNo}` : params.userDetails.MobileNo,
      customer_role: params.user ? 'Vendor':'Cust',
    };

    const response = await fetch(
      `https://app.osbrm.com/api/Excel/Gate1CF?TType=Wallet&order_amount=${amount}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
        body: JSON.stringify(data),
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const CheckWalletTransactionStatus = createAsyncThunk(
  'commonSlice/CheckWalletTransactionStatus',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Customer/CheckWalletTransactionStatus?orderID=${params}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
// get dashboard cout
export const getDashboardCount = createAsyncThunk('commonSlice/getDashboardCount', async () => {
  const response = await fetch('https://app.osbrm.com/GetDashboardCount', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
// refferal history
export const getReferralHistory = createAsyncThunk('commonSlice/getReferralHistory', async () => {
  const response = await fetch('https://app.osbrm.com/GetReferralHistory', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getStateListZone = createAsyncThunk('commonSlice/getStateListZone', async () => {
  const response = await fetch('https://app.osbrm.com/api/Common/GetStatesWithZones', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getStateListZoneByCity = createAsyncThunk(
  'commonSlice/getStateListZone',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Common/GetCityWithZonesByState?stateid=${params.value}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getStateListCityByZone = createAsyncThunk(
  'commonSlice/getStateListZone',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Common/GetZonesWithRepsectToCity?StateId=${params.body.selectedState.value}&CityId=${params.body.cityID.value}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getCityList = createAsyncThunk('commonSlice/getCityList', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/GetAllCityByStateId/${params.value ? params.value : params}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getStateListZoneByPin = createAsyncThunk(
  'commonSlice/getStateListZoneByPin',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Common/GetPincodeByZoneId?id=${params.body.zoneID.value}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const getAllRole = createAsyncThunk('commonSlice/getAllRole', async () => {
  const response = await fetch(`https://app.osbrm.com/GetAllRole`, {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getAllZone = createAsyncThunk('commonSlice/getAllZone', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Common/GetAllZone`, {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const setReferralAmount = createAsyncThunk(
  'commonSlice/setReferralAmount',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/SetReferralCost?ReferralCost=${params.amount.amount}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const TipAmount = createAsyncThunk('commonSlice/TipAmount', async (params) => {
  const amount = params.Action == 'Insert' ? params.amount.amount : params.amount;
  const response = await fetch(
    `https://app.osbrm.com/CrudTipsManage?Action1=${params.Action}&TipAmnt=${amount}&id=${params.id}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const AcWalletAmount = createAsyncThunk('commonSlice/AcWalletAmount', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Account/AddMoneyToAccWallet?Action=Credit&TransAmount=${params.amount.amount}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getTips = createAsyncThunk('commonSlice/getAllZone', async () => {
  const response = await fetch(`https://app.osbrm.com/GetTips`, {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const DeReActivateTip = createAsyncThunk('commonSlice/DeReActivateTip', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/DeReActivateAll?action1=Tip&id=${params.id}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getAllCustomer = createAsyncThunk('commonSlice/getAllZone', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Customer/GetAllCustomers`, {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getSalesReportService = createAsyncThunk(
  'commonSlice/getSalesReportService',
  async (params) => {
    const startDate = params.FromDate ?? '';
    const Year = params.Year ?? 0;
    const response = await fetch(
      `https://app.osbrm.com/api/Common/SalesReportService?Type=${params.type}&FromDate=${startDate}&ToDate=${params.ToDate}&Month=${params.Month}&Year=${Year}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getVendorPurchaseReport = createAsyncThunk(
  'commonSlice/getVendorPurchaseReport',
  async (params) => {
    const startDate = params.FromDate ?? '';
    const Year = params.Year ?? 0;
    const response = await fetch(
      `https://app.osbrm.com/api/Common/PurchaseReportVendor?Type=${params.type}&FromDate=${startDate}&ToDate=${params.ToDate}&Month=${params.Month}&Year=${Year}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const updateWalletBalance = createAsyncThunk('commonSlice/addCartItem', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/UpdateCustomerWalletBalance?Amount=${params.fields.amount}&MobileNo=${params.fields.mobile}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
  const data = await response.json();
  const res = JSON.parse(data);
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
// getWalletHistory
export const getWalletHistoryCustomer = createAsyncThunk(
  'commonSlice/getWalletHistoryCustomer',
  async () => {
    const response = await fetch(
      `https://app.osbrm.com/GetWalletHistoryForCustomer?CustomerMobileNo=${''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

// subscription
export const getSubscriptionDetails = createAsyncThunk(
  'webservice/getSubscriptionDetails',
  async () => {
    const response = await fetch(`https://app.osbrm.com/GetSubscriptionDetails`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    FetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, zoneResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, zoneDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        zoneDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        zoneDeleteSucces: false,
        zoneDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const { FetchSuccess, FetchFailed, DeleteSuccess, DeleteFailed, DeleteInitial } =
  commonSlice.actions;
export default commonSlice.reducer;
