import * as Icon from 'react-feather';

const SidebarVendor = [
  {
    title: 'Dashboard',
    href: '/Dashboard/HrDashboard',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Home />,
    id: 1,
  },
  // {
  //   title: 'Pay Module',
  //   href: '/Dashboard/HrDashboard',
  //   icon: <Icon.Play />,
  //   id: 2,
  //   // collapisble: true,
  //   // children: [
  //   //   {
  //   //     title: 'Salary Disbursed',
  //   //     href: '/PayModule/SalaryDisbursed',
  //   //     icon: <Icon.Award />,
  //   //   },
  //   //   {
  //   //     title: 'Salary Slip',
  //   //     href: '/PayModule/SalarySlip',
  //   //     icon: <Icon.Award />,
  //   //   },
  //   // ],
  // },
  {
    title: 'Employee',
    href:  '/Employee/Employeelist',
    icon: <Icon.User />,
    id: 3,
    collapisble: true,
    children: [
      // {
      //   title: 'Employee Add',
      //   href: '/employee/employeeadd',
      //   icon: <Icon.Disc />,
      // },
      {
        title: 'Employee List',
        href:  '/Employee/Employeelist',
        icon: <Icon.Award />,
      },
    ],
  },
  {
    title: 'Recruit Staff',
    href: '/Dashboard/HrDashboard',
    icon: <Icon.User />,
    id: 4,
    collapisble: true,
    children: [
      {
        title: 'Recruiting Count',
        href: '/RecruitingStaff/RecruitingCount',
        icon: <Icon.Award />,
      },
      {
        title: 'Recruiting History',
        href: '/RecruitingStaff/RecrutingHistory',
        icon: <Icon.Award />,
      },
   
      {
        title: 'Screened List',
        href: '/RecruitingStaff/ScreenedList',
        icon: <Icon.Award />,
      },
      {
        title: 'Interviewed',
        href: '/RecruitingStaff/Interviewed',
        icon: <Icon.Disc />,
      },

      {
        title: 'Finalised',
        href: '/RecruitingStaff/FinalisedList',
        icon: <Icon.Award />,
      },
      {
        title: 'OnBoarding',
        href: '/RecruitingStaff/AppointedList',
        icon: <Icon.Award />,
      },
      {
        title: 'Admin Rejected List ',
        href: '/RecruitingStaff/RejectedCandidates',
        icon: <Icon.Award />,
      },
    ],
  },
  {
    title: 'Attendance',
    href: '/Reports/AttendanceList',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Home />,
    id: 5,
  },
  // {
  //   title: 'Reports',
  //   href: '/Dashboard/HrDashboard',
  //   icon: <Icon.User />,
  //   id: 5,
  //   collapisble: true,
  //   children: [
  //     {
  //       title: 'Monthwise Recruitment',
  //       href: '/Reports/MonthwiseRecruitmentList',
  //       icon: <Icon.Disc />,
  //     },
  //     {
  //       title: 'Attendance',
  //       href: '/Reports/AttendanceList',
  //       icon: <Icon.Award />,
  //     },
  //     {
  //       title: 'Monthly Employee details',
  //       href: '/Reports/MontlyEmployeeDetails',
  //       icon: <Icon.Disc />,
  //     },
  //     {
  //       title: 'Salary Disbursed',
  //       href: '/PayModule/SalaryDisbursed',
  //       icon: <Icon.Award />,
  //     },
  //     {
  //       title: 'Salary Slip',
  //       href: '/PayModule/SalarySlip',
  //       icon: <Icon.Award />,
  //     },
  //     {
  //       title: 'Expenses Disbursed',
  //       href: '/PayModule/Expensesdisbursed',
  //       icon: <Icon.Award />,
  //     },
  //     {
  //       title: 'Salaray Process Manual',
  //       href: '/PayModule/SalarayProcessManual',
  //       icon: <Icon.Award />,
  //     },
  //   ],
  // },
  //
  // {
  //   title: 'Onboarding',
  //   href: '/Dashboard/HrDashboard',
  //   icon: <Icon.Airplay />,
  //   id: 6,
  // },
  // {
  //   title: 'Rules & Regulation',
  //   href: '/Dashboard/HrDashboard',
  //   icon: <Icon.Activity />,
  //   id: 7,
  // },
];

export default SidebarVendor;
