import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const initialState = {
  // Delete Banner
  bannerDeleteResponse: {
    status: false,
    message: '',
  },
  bannerDeleteSucces: false,
  // Delete Banner End
  error: '',
  loading: false,
};
export const banner = createAsyncThunk('banner/bannerAdd', async (params) => {
  const data = {
    path: params.bannerImage ? params.bannerImage : '',
    imagebase64Data: params.bannerImage ? params.bannerImage : '' ,
    fileName: params.imageFilename ? params.imageFilename : 'youtubeVideo',
    type: params.selectedScreenName,
    youtubeLink: params.ytUrl,
  };
  console.log(data)
  const response = await fetch('https://app.osbrm.com/api/Common/AddBanner', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getBannerweb = createAsyncThunk('banner/getBannerweb', async () => {
  const response = await fetch('https://app.osbrm.com/api/Common/GetAllBanner', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const bannerDelete = createAsyncThunk('banner/bannerDelete', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Common/DeleteBanner?id=${params.deletId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const bannerStatus = createAsyncThunk('banner/status', async (params) => {
  const status = params.status == 1;
  const response = await fetch(
    `https://app.osbrm.com/api/Common/ChangeBannerStatus?id=${params.id}&status=${status}`,
    {
      method: 'PUT',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getNewLaunchSlider = createAsyncThunk('banner/getNewLaunchSlider', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/GetHomeScreenAdvsByAdvType?AdvType=${params}`,
    {
      method: 'GET',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getCouponCode = createAsyncThunk('banner/getCouponCode', async (params) => {
  const response = await fetch(`https://app.osbrm.com/GetCouponsDetails?id=${params}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getSpecialPackagesSlider = createAsyncThunk('banner/getNewLaunchSlider', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Marketing/GetSpecialPackages`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

////// for advertisement purpose
export const advertisement = createAsyncThunk('banner/advertisement', async (params) => {
  const data = {
    homeScreenAds: {
      description: params.Data.AdvsName,
      discountDesc: params.Data.AdvsDiscount,
      imageUrl: params.advertiseImage,
      imageName: params.imageFilename,
      advType: params.advstype,
      addedBy: params.UserID,
      addedByRole: 'A',
      approveCO_Status: 'Y',
      rejectCO_status: '',
    },
    couponsOffers: {
      id: 0,
      couponId: 0,
      serviceId: params.advstype === 'CO' ? params.selectedService : 0,
      discountType: params.advstype === 'CO' ? params.selectedDiscountType : '',
      discount: params.advstype === 'CO' ? params.discount : 0,
      couponCode: '',
    },
  };

  const response = await fetch('https://app.osbrm.com/HomeScreenAdvsInsert', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const getAdvsDetails = createAsyncThunk('banner/getAdvsDetails', async () => {
  const response = await fetch('https://app.osbrm.com/GetHomeScreenAdvsById', {
    method: 'GET',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const advsDelete = createAsyncThunk('banner/advsDelete', async (params) => {
  const response = await fetch(`https://app.osbrm.com/HomeScreenAdvsDelete?id=${params.deletId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
/*  */
export const UpdateAdvsDetails = createAsyncThunk('banner/UpdateAdvsDetails', async (params) => {
  const data = {
    homeScreenAds: {
      id: params.id,
      description: params.advsName,
      discountDesc: params.advsDiscount,
      imageUrl: params.advertiseImage ? params.advertiseImage : '',
      imageName: params.imageFilename,
      advType: params.advstype.value,
      addedBy: params.UserID,
      addedByRole: 'A',
      approveCO_Status: 'Y',
      rejectCO_status: '',
    },
    couponsOffers: {
      id: params.advstype.value === 'CO' ? params.couponCodeId : 0,
      couponId: params.advstype.value === 'CO' ? params.couponId : 0,
      serviceId: params.advstype.value === 'CO' ? params.selectedService.value : 0,
      discountType: params.advstype.value === 'CO' ? params.selectedDiscountType.value : '',
      discount: params.advstype.value === 'CO' ? params.discount : 0,
      couponCode: '',
    },
  };


  const response = await fetch('https://app.osbrm.com/HomeScreenAdvsUpdate', {
    method: 'PUT',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const BannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    banFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    banFetchFailed(state, action) {
      return { ...state, bannerResponse: { status: true, message: action.payload } };
    },
    banDeleteSuccess(state) {
      return { ...state, loading: false, bannerDeleteSucces: true };
    },
    banDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        bannerDeleteResponse: { status: true, message: action.payload },
      };
    },
    banDeleteInitial(state) {
      return {
        ...state,
        bannerDeleteSucces: false,
        bannerDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  banFetchSuccess,
  banFetchFailed,
  banDeleteSuccess,
  banDeleteFailed,
  banDeleteInitial,
} = BannerSlice.actions;
export default BannerSlice.reducer;
