import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// import productData from '../../productData';

/* eslint-disable */
const initialState = {
  cart: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
  items: [],
  loading :false,
  error:null,
  totalQuantity: 0,
  totalPrice: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const find = state.cart.findIndex((item) => item.id === action.payload.id);
      if (find >= 0) {
        state.cart[find].quantity += 1;
          toast.info('Increased product quantity', {
          position: 'bottom-left',
        });
      } else {
        state.cart.push(action.payload);
        toast.success('Product added to cart', {
        position: 'bottom-left',
        });
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cart));
    },

    
    getCartTotal: (state) => {
      const { totalQuantity, totalPrice } = state.cart.reduce(
        (cartTotal, cartItem) => {
          const { price, quantity } = cartItem;
          const itemTotal = price * quantity;
          cartTotal.totalPrice += itemTotal;
          cartTotal.totalQuantity += quantity;
          return cartTotal;
        },
        {
          totalPrice: 0,
          totalQuantity: 0,
        },
      );
      state.totalPrice = parseInt(totalPrice.toFixed(2));
      state.totalQuantity = totalQuantity;
    },
    removeItem: (state, action) => {
      state.cart = state.cart.filter((item) => item.id !== action.payload);
      toast.error('Product removed from cart', {
        position: 'bottom-left',
      });
      localStorage.setItem('cartItems', JSON.stringify(state.cart));
      return state;
    },
    increaseItemQuantity: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      localStorage.setItem('cartItems', JSON.stringify(state.cart));
    },

    decreaseItemQuantity: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.quantity > 0) {
          if (item.id === action.payload) {
            return { ...item, quantity: item.quantity - 1 };
          }
        }
        return item;
      });
      localStorage.setItem('cartItems', JSON.stringify(state.cart));
    },
  },
});

export const { addToCart, getCartTotal, removeItem, increaseItemQuantity, decreaseItemQuantity } =
  cartSlice.actions;

export default cartSlice.reducer;
