import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};
const initialState = {
  subcategoryDeleteResponse: {
    status: false,
    message: '',
  },
  subcategoryDeleteSucces: false,
  error: '',
  loading: false,
};
export const servicesAdd = createAsyncThunk('services/servicesAdd', async (params) => {
  const rowData = {
    heading: params.serviceName.serviceName,
    price: params.serviceName.servicePrice,
    subCategoryId: params.selectSub.value,
    pDetails: params.serviceName.serviceDisscription,
    discount: params.serviceName.serviceDisscount,
    imagebase64Data: params.proof,
    fileName: params.imageFilename,
    imagebase64DataBanner:
      'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8BwACDQAAgQPV5hAAAAABJRU5ErkJggg==',
    fileNameBanner: 'banner.jpeg',
    subCategoryName: params.selectSub.label,
    roleId: '2',
    gst: params.serviceName.gst,
    visitingCharges: '0',
  };

  const response = await fetch('https://app.osbrm.com/api/Common/AddService', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(rowData),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const AddtoFav = createAsyncThunk('services/AddtoFav', async (params) => {

  const rowData = {
    id: params.favId ? params.favId : 0,
    custId: params.userDetails.id,
    servicesId: params.id,
    createDate: '2023-10-03T13:20:44.236Z',
  };

  const response = await fetch('https://app.osbrm.com/api/Customer/UserFavoritesAddDel', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(rowData),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const updateServicesDetails = createAsyncThunk(
  'services/updateServicesDetails',
  async (params) => {
    const rowData = {
      heading: params.heading,
      price: params.price,
      subCategoryId: params.subCatID,
      pDetails: params.Details,
      discount: params.discount,
      imagebase64Data: params.proof ? params.proof : '',
      fileName: params.imageFilename ? params.imageFilename : '',
      gst: params.gst,
      imagebase64DataBanner:
        'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8BwACDQAAgQPV5hAAAAABJRU5ErkJggg==',
      fileNameBanner: 'banner.jpeg',
      subCategoryName: params.selectSub,
    };

    const response = await fetch(
      `https://app.osbrm.com/api/Common/UpdateService/${params.servicesID}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
        body: JSON.stringify(rowData),
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getServices = createAsyncThunk('services/getServices', async () => {
  const response = await fetch('https://app.osbrm.com/api/Common/GetAllServices', {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getFavservices = createAsyncThunk('services/getFavservices', async (params) => {

  const response = await fetch(`https://app.osbrm.com/api/Customer/GetUserFavorites?Id=${params}`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const subcategoryDelete = createAsyncThunk('services/delete', async (data) => {
  const response = await fetch(
    'http://uat.prasadsolution.com/app/ProductCategory.asmx/DeleteSubcategory',
    {
      method: 'POST',
      headers: header,
      body: `id=${data.deletId}&Authenticationkey=${'123456'}`,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const subcategoryList = createAsyncThunk('services/list', async (data) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Common/GetAllSubCategoryByCategoryId/${data}`,
    {
      method: 'GET',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const DeReActivateService = createAsyncThunk(
  'services/DeReActivateService',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeReActivateAll?action1=Services&id=${params.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const AddServiceExcel = createAsyncThunk('services/AddServiceExcel', async (params) => {
  const data = params.map((item) => ({
    heading: `${item.heading}`,
    price: `${item.price}`,
    categoryName: `${item.categoryname}`,
    pDetails: `${item.details}`,
    discount: `${item.discount}`,
    imagebase64Data: '',
    fileName: '',
    imagebase64DataBanner: '',
    fileNameBanner: '',
    subCategoryName: `${item.subcategoryname}`,
    roleId: 2,
    gst: `${item.gst}`,
    visitingCharges: '0',
  }));

  const response = await fetch('https://app.osbrm.com/api/Excel/AddServiceExcel', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'True') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getAllServicesBySubCategory = createAsyncThunk(
  'services/getAllServicesBySubCategory',
  async (data) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Common/GetAllServicesBySubCategory/${data}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const CheckAvailabilityOfCatByPincode = createAsyncThunk(
  'services/CheckAvailabilityOfCatByPincode',
  async (data) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Common/CheckAvailabilityOfCatByPincode?Pincode=${data.pinCode}&CategoryId=${data.categoryId}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deleteSubServices = createAsyncThunk('services/deleteSubServices', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Common/DeleteService?id=${params.deletId}`,
    {
      method: 'DELETE',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getServiceCatDetails = createAsyncThunk(
  'services/deleteSubServices',
  async (params) => {
    const response = await fetch(`https://app.osbrm.com/api/Common/GetServicesByID/${params}`, {
      method: 'GET',
      headers: header,
    });
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const categoryStatus = createAsyncThunk('services/status', async (data) => {
  const response = await fetch('http://uat.prasadsolution.com/app/Baner.asmx/changeBannerStatus', {
    method: 'POST',
    headers: header,
    body: `id=${data.statusId}&Authenticationkey=${'123456'}`,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getBannerBySubcategory = createAsyncThunk(
  'services/getBannerBySubcategory',
  async (data) => {
    const temp = data ?? '';
    const response = await fetch(
      `https://app.osbrm.com/GetServiceImagesVideos?SubCategoryId=${temp}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deleteBannerServices = createAsyncThunk(
  'services/deleteBannerServices',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DelServiceImagesVideos?Id=${params.deletId}`,
      {
        method: 'PUT',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const addServiceImges = createAsyncThunk('services/addServiceImges', async (params) => {
  // const rowData = {
  //   heading: params.heading,
  //   price: params.price,
  //   subCategoryId:params.subCatID,
  //   pDetails: params.Details,
  //   discount: params.discount,
  //   imagebase64Data: params.proof,
  //   fileName: params.imageFilename,
  //   imagebase64DataBanner:params.bannerImage,
  //   fileNameBanner:params.imageFilename,
  //   subCategoryName:params.selectSub,
  // };

  const response = await fetch(
    `https://app.osbrm.com/AddServiceImagesVideos?ServiceId=${params.selectSub.value}&Vidtype=${params.selectedType.value}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(params.bannerImage),
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const SubCategorySlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    catFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    catFetchFailed(state, action) {
      return { ...state, subcategoryResponse: { status: true, message: action.payload } };
    },
    catDeleteSuccess(state) {
      return { ...state, loading: false, subcategoryDeleteSucces: true };
    },
    catDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        subcategoryDeleteResponse: { status: true, message: action.payload },
      };
    },
    catDeleteInitial(state) {
      return {
        ...state,
        subcategoryDeleteSucces: false,
        subcategoryDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  catFetchSuccess,
  catFetchFailed,
  catDeleteSuccess,
  catDeleteFailed,
  catDeleteInitial,
} = SubCategorySlice.actions;
export default SubCategorySlice.reducer;
