import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const header = {
  Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const initialState = {
  employeeSliceDeleteResponse: {
    status: false,
    message: '',
  },
  updateResponse: false,
  error: '',
  loading: false,
};

export const addProfessional = createAsyncThunk('employeeSlice/addProfessional', async (params) => {
  const data = {
    fullName: `${params.fields.FirstName.concat(' ', params.fields.LastName)}`,
    roleId: `${params.professionRole.value}`,
    cityId: `${params.selectedCity.value}`,
    stateId: `${params.selectedState.value}`,
    addressLine1: `${params.address}`,
    addressLine2: `${params.address}`,
    email: `${params.fields.email}`,
    birthDate: `${params.fields.dob}`,
    panCardNumber: `${params.fields.pan_number}`,
    addhaarCardNumber: `${params.fields.adhar_number}`,
    vendorId: `${params.vendorSelect.value ? params.vendorSelect.value : ''}`,
    pincode: `${params.fields.pincode}`,
    contactNo: `${params.fields.mobileNumber}`,
    altContactNo: `${params.fields.mobileNumber}`,
    serviceId: '0',
    professionalServicesDetailsList: params.selectedServices,
    professionalZoneDetailsList: params.selectedZones,
  };
  const response = await fetch(
    `https://app.osbrm.com/api/Professional/AddProfessional?CandidateId=${'0'}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
      body: JSON.stringify(data),
    },
  );
  const res = await response.json();

  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});

export const AddProfessionalExcel = createAsyncThunk('employeeSlice/AddProfessionalExcel', async (params) => {

  const data = params.map((item) => ({
    id: 0,
    fullName: item.fullname,
    roleName: `${item.role}`,
    cityName: `${item.city}`,
    stateName:`${item.state}`,
    addressLine1: `${item.addressline1}`,
    addressLine2: `${item.addressline2}`,
    email: `${item.email}`,
    birthDate: `${item.birthdate}`,
    panCardNumber: `${item.pannumber}`,
    addhaarCardNumber: `${item.aadharnumber}`,
    vendorName: `${item.vendormobileno ? item.vendormobileno : ''}`,
    pincode: `${item.pincode}`,
    contactNo: `${item.phone}`,
    altContactNo: `${item.phone}`,
    serviceName: `${''}`,
  }));

  const response = await fetch('https://app.osbrm.com/api/Excel/AddProfessionalExcel', {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
      'Content-Type': 'application/json;',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();

  if (res.status === 'True') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const addProfessionalHR = createAsyncThunk(
  'employeeSlice/addProfessionalHR',
  async (params) => {
    const data = {
      fullName: `${params.firstname}`,
      roleId: `${1}`,
      cityId: `${params.selectedCity.value}`,
      stateId: `${params.selectedState.value}`,
      addressLine1: `${params.address}`,
      addressLine2: `${params.address}`,
      email: `${params.email}`,
      birthDate: `${params.dob}`,
      panCardNumber: `${params.fields.pan_number}`,
      addhaarCardNumber: `${params.fields.adhar_number}`,
      vendorId: `${params.vendorSelect.value ? params.vendorSelect.value : ''}`,
      pincode: `${params.pinCode}`,
      contactNo: `${params.phone}`,
      altContactNo: `${params.phone}`,
      serviceId: '11',
      professionalServicesDetailsList: params.selectedServices,
      professionalZoneDetailsList: params.selectedZones,
    };
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/AddProfessional?CandidateId=${params.candidateData.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
        body: JSON.stringify(data),
      },
    );
    const res = await response.json();

    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const updateProfessionalDetails = createAsyncThunk(
  'employeeSlice/updateProfessionalDetails',
  async (params) => {
    const fullname = params.data.firstName.concat(' ', params.data.lastName);
    const rowData = {
      id: params.data.userID,
      fullName: fullname,
      roleId: `${params.data.professionRole.value}`,
      cityId: `${params.data.selectCity.value}`,
      stateId: `${params.data.selectState.value}`,
      addressLine1: params.data.address,
      addressLine2: params.data.address,
      email: params.data.emailAddress,
      birthDate: params.data.DOB,
      panCardNumber: params.data.panNumber,
      addhaarCardNumber: params.data.adharNumber,
      vendorId: `${params.data.vendorID}`,
      pincode: params.data.pincode,

      // createdDate: '2023-07-17T10:05:50.317Z',
      contactNo: params.data.phoneNumber,
      altContactNo: params.data.phoneNumber,
      serviceId: '11',
      professionalServicesDetailsList: [
        {
          serviceId: 0,
        },
      ],
      professionalZoneDetailsList: [
        {
          zoneId: 0,
        },
      ],
    };
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/UpdateProfessional?id=${params.data.userID}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
        body: JSON.stringify(rowData),
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getAllProfessional = createAsyncThunk(
  'employeeSlice/getAllProfessional',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/GetProfnVendList?action1=${params.Action}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetZoneByEmplId = createAsyncThunk('employeeSlice/GetZoneByEmplId', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Professional/GetZoneByProfessional?EmpId=${params.value}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
        'Content-Type': 'application/json;',
      },
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const GetServiceByEmplId = createAsyncThunk(
  'employeeSlice/GetServiceByEmplId',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/GetServiceByProfessional?EmpId=${params.value}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deleteZoneByEmpId = createAsyncThunk(
  'employeeSlice/deleteZoneByEmpId',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/DeleteZoneProfessional?id=${params.deletId}`,
      {
        method: 'DELETE',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const deleteServiceByEmpId = createAsyncThunk(
  'employeeSlice/deleteServiceByEmpId',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/DeleteServiceProfessional?id=${params.deletId}`,
      {
        method: 'DELETE',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const AddServiceEmp = createAsyncThunk('employeeSlice/AddServiceEmp', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Professional/AddServiceProfessionalwise?ServiceId=${params.serviceSelect.value}&EmpId=${params.empIdService}`,
    {
      method: 'PUT',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const AddZoneEmp = createAsyncThunk('employeeSlice/AddZoneEmp', async (params) => {
  const response = await fetch(
    `https://app.osbrm.com/api/Professional/AddZoneProfessionalwise?Zoneid=${params.zonalSelect.value}&EmpId=${params.empIdZone}`,
    {
      method: 'PUT',
      headers: header,
    },
  );
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const deleteProfessional = createAsyncThunk(
  'employeeSlice/deleteProfessional',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/DeleteProfessional?MobileNo=${params.deletId}`,
      {
        method: 'DELETE',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const getProfessionalDetails = createAsyncThunk(
  'employeeSlice/GetProfessionalBy',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Professional/GetProfessionalByID/${params.userID}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const DeReActivateProfessional = createAsyncThunk(
  'employeeSlice/DeReActivateTip',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/DeReActivateAll?action1=Professional&id=${params.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded,text/plain,*/*',
          'Content-Type': 'application/json;',
        },
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const AssignOrderToTech = createAsyncThunk(
  'employeeSlice/GetProfessionalBy',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/AssignTechnician?TechnicianId=${params.selectTech.value}&ServiceId=${params.serviceID}&OrderId=${params.orderID}`,
      {
        method: 'POST',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);

// VENDORS API LIST
export const getAllVendors = createAsyncThunk('employeeSlice/getAllVendors', async () => {
  const response = await fetch(`https://app.osbrm.com/api/Vendor/GetAllVendor`, {
    method: 'GET',
    headers: header,
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const getVendorDetails = createAsyncThunk(
  'employeeSlice/getVendorDetails',
  async (params) => {
    const response = await fetch(
      `https://app.osbrm.com/api/Vendor/GetVendorByID/${params.value}`,
      {
        method: 'GET',
        headers: header,
      },
    );
    const res = await response.json();
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetUnAssignedOrdersForProffessional = createAsyncThunk(
  'technicianLeadSlice/GetAssignedOrdersForProffessional',
  async () => {
    const response = await fetch(`https://app.osbrm.com/GetUnassignedOrders`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const res = JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const GetActivateTechEng = createAsyncThunk(
  'technicianLeadSlice/GetActivateTechEng',
  async () => {
    const response = await fetch('https://app.osbrm.com/api/Professional/GetActivateTechsEngg', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const res = await JSON.parse(data);
    if (res.status === 'true') {
      return res;
    }
    if (res.status === 'false') {
      return res;
    }
    return res;
  },
);
export const employeeSlice = createSlice({
  name: 'employeeSlice',
  initialState,
  reducers: {
    catFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    catFetchFailed(state, action) {
      return { ...state, employeeSliceResponse: { status: true, message: action.payload } };
    },
    catDeleteSuccess(state) {
      return { ...state, loading: false, updateResponse: true };
    },
    catDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        employeeSliceDeleteResponse: { status: true, message: action.payload },
      };
    },
    catDeleteInitial(state) {
      return {
        ...state,
        updateResponse: false,
        employeeSliceDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  catFetchSuccess,
  catFetchFailed,
  catDeleteSuccess,
  catDeleteFailed,
  catDeleteInitial,
} = employeeSlice.actions;
export default employeeSlice.reducer;
