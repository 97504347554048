import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
 

// const userLogin = JSON.parse(localStorage.getItem('userLogin'));

// const initialState = userLogin ? { isLoggedIn: true, userLogin } : { isLoggedIn: false, userLogin: '' };
// export const loginUser = createAsyncThunk('webservice/loginUser', async (params) => {
//  ;
//   const response = await fetch(`https://app.osbrm.com/login?username=${params.UserName.mobileNumber}&password=${params.UserName.Password}`, {
//     method: 'POST',
//     headers: header,
//   });
//   const res = await response.json();
//   if (res.status === 'Success') {
//     localStorage.setItem('user', JSON.stringify(res.Data[0]));
//     return res;
//   }
//   if (res.status === 'false') {
//     return res;
//   }
//   return res;
// });
const CryptoJS = require('crypto-js'); 

const key =
  'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY4Mzk2MzQwNSwiaWF0IjoxNjgzOTYzNDA1fQ.eHXysz4LDwqHLjsVUr5RwnYdSIw72Wf7Mv7JlDJh2AI';

const userLogin = JSON.parse(sessionStorage.getItem(`userLogin`))
? JSON.parse(
    CryptoJS.AES.decrypt(JSON.parse(sessionStorage.getItem(`userLogin`)), key).toString(
      CryptoJS.enc.Utf8,
    ),
  )
: null;

// const userLogin = JSON.parse(localStorage.getItem('userLogin'));
const userToken = JSON.parse(sessionStorage.getItem(`userToken`))
? JSON.parse(
    CryptoJS.AES.decrypt(JSON.parse(sessionStorage.getItem(`userToken`)), key).toString(
      CryptoJS.enc.Utf8,
    ),
  )
: null;

const initialState =  
userLogin ? { isLoggedIn: true, userLogin, userToken, } : { isLoggedIn: false, userLogin: '', userToken:'' };


export const loginUser = createAsyncThunk('webservice/loginUser', async (params) => {

  const response = await fetch(`https://app.osbrm.com/Userlogin?MobileNoOrEmail=${params.UserName.mobileNumber}&Password=${params.UserName.Password}&lattitude=${params.lat}&longitude=${params.lng}&IPAdress=${params.ipAddress}&Device=${'Web'}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const  res = await response.json();
  const data = JSON.parse(res);
  if (data.status === 'true') {

    const Login = CryptoJS.AES.encrypt(JSON.stringify(data.data[0]), key).toString();
    const userToken = CryptoJS.AES.encrypt(JSON.stringify(data.Token), key).toString();

    sessionStorage.setItem('userToken', JSON.stringify(userToken));
    sessionStorage.setItem('userLogin', JSON.stringify(Login));
    return data;
  }
  if (data.status === 'false') {
    return data;
  }
  return data;
});
export const logout = () => {
  sessionStorage.removeItem('userLogin');
  sessionStorage.removeItem('userToken');
  // sessionStorage.clear();
};
export const userSignUp = createAsyncThunk('webservice/userSignUp', async (params) => {
  const data = {
    fullName: params.fields.FirstName,
    mobileNo: params.fields.mobileNumber,
    email: params.fields.emailID,
    city: params.selectedCity.label,
    pinCode: params.selectPin.label,
    referral: params.referCodeValue ? params.referCodeValue  : '',
    password:params.fields.password,
    zoneId: `${params.zonalSelect.value}`,
  };
  const response = await fetch('https://app.osbrm.com/api/Customer/Signup', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const ForgotPasswordOfUser = createAsyncThunk('webservice/ForgotPassword', async (data) => { 
  const response = await fetch(`https://app.osbrm.com/api/Common/ForgotPasswordAll?Role=${data.Role}&Phone=${data.UserName.mobileNumber}&Otp=1234&NewPass=${data.UserName.Password  }`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
  },
  });
  const res1 = await response.json();
  const res = await JSON.parse(res1)
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
export const ChangePassword = createAsyncThunk('webservice/ChangePassword', async (data) => {
  const response = await fetch(`https://app.osbrm.com/api/Common/ChangePasswordAll?Role=${data.Role}&Phone=${data.userDetails.MobileNo}&OldPassword=${data.oldPass}&NewPassword=${data.newPass}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
  },
  });
  const res1 = await response.json();
  const res = await JSON.parse(res1)
  if (res.status === 'true') {
    return res;
  }
  if (res.status === 'false') {
    return res;
  }
  return res;
});
 


export const webSiteslice = createSlice({
  name: 'webservice',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      return {...state, userLogin: action.payload.data[0]};
    },
    loginFailed(state, action) {
      return { ...state, loginResponse: { status: true, msg: action.payload } };
    },
    setLoginStatesFalse(state) {
      return { ...state, fetchError: false };
    },
    FethSuccess(state) {
      return { ...state, loading: false, cartUpdate: true };
    },
  },
});
export const {
  loginSuccess, loginFailed,
  FethSuccess
} = webSiteslice.actions;
export default webSiteslice.reducer;
