import React from 'react';
import { Nav } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import SidebarAdmin from '../sidebardata/SidebarAdmin';
import SidebarCall from '../sidebardata/SidebarCall';
import SidebarTech from '../sidebardata/SidebarTech';
import SidebarVendor from '../sidebardata/SidebarVendor';
import SidebarHr from '../sidebardata/SidebarHr';
import SidebarInventory from '../sidebardata/SidebarInventory';
 import SidebarAccount from '../sidebardata/SidebarAccount';
 import SidebarMarketing from '../sidebardata/SidebarMarketing';
import NavItemContainer from './NavItemContainer';
import NavSubMenu from './NavSubMenu';
import user1 from '../../../assets/images/users/user4.jpg';

const Sidebar = () => {
  const location = useLocation();
  const currentURL = location.pathname.split('/').slice(0, -1).join('/');
  const { user: currentUser } = useSelector((state) => state.user);
  const activeBg = useSelector((state) => state.customizer.sidebarBg);
  const isFixed = useSelector((state) => state.customizer.isSidebarFixed);
  // const dispatch = useDispatch();

  return (
    <div className={`sidebarBox shadow bg-${activeBg} ${isFixed ? 'fixedSidebar' : ''}`} >
      <SimpleBar style={{ height: '100%' }}>
        <div className="py-3 px-4 d-flex align-items-center border-bottom-sidebar">
          <img src={user1} alt="user" width="30" className="rounded-circle" />
          <div className="ms-3 opacity-75 text-truncate user-name ">{currentUser.FullName}</div>
        </div>
        {/********Sidebar Content*******/}
        <div  className="mb-4 pb-5">
          <Nav vertical className={activeBg === 'white' ? '' : 'lightText'}>
            {currentUser.Role === 'A' ? (
              <>
                {SidebarAdmin.map((navi) => {
                  if (navi.caption) {
                    return (
                      <div className="navCaption text-uppercase mt-4" key={navi.caption}>
                        {navi.caption}
                      </div>
                    );
                  }
                  if (navi.children) {
                    return (
                      <NavSubMenu
                        key={navi.id}
                        icon={navi.icon}
                        title={navi.title}
                        items={navi.children}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        // toggle={() => toggle(navi.id)}
                        // collapsed={collapsed === navi.id}
                        isUrl={currentURL === navi.href}
                      />
                    );
                  }
                  return (
                    <NavItemContainer
                      key={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                })}
              </>
            ) : currentUser.Role === 'CD' ? (
              <>
                {SidebarCall.map((navi) => {
                  if (navi.caption) {
                    return (
                      <div className="navCaption text-uppercase mt-4" key={navi.caption}>
                        {navi.caption}
                      </div>
                    );
                  }
                  if (navi.children) {
                    return (
                      <NavSubMenu
                        key={navi.id}
                        icon={navi.icon}
                        title={navi.title}
                        items={navi.children}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        // toggle={() => toggle(navi.id)}
                        // collapsed={collapsed === navi.id}
                        isUrl={currentURL === navi.href}
                      />
                    );
                  }
                  return (
                    <NavItemContainer
                      key={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                })}
              </>
            ) : currentUser.Role === 'TE' ? (
              <>
                {SidebarTech.map((navi) => {
                  if (navi.caption) {
                    return (
                      <div className="navCaption text-uppercase mt-4" key={navi.caption}>
                        {navi.caption}
                      </div>
                    );
                  }
                  if (navi.children) {
                    return (
                      <NavSubMenu
                        key={navi.id}
                        icon={navi.icon}
                        title={navi.title}
                        items={navi.children}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        // toggle={() => toggle(navi.id)}
                        // collapsed={collapsed === navi.id}
                        isUrl={currentURL === navi.href}
                      />
                    );
                  }
                  return (
                    <NavItemContainer
                      key={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                })}
              </>
            ) :  currentUser.Role === 'IM' ? (
              <>
                {SidebarInventory.map((navi) => {
                  if (navi.caption) {
                    return (
                      <div className="navCaption text-uppercase mt-4" key={navi.caption}>
                        {navi.caption}
                      </div>
                    );
                  }
                  if (navi.children) {
                    return (
                      <NavSubMenu
                        key={navi.id}
                        icon={navi.icon}
                        title={navi.title}
                        items={navi.children}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        // toggle={() => toggle(navi.id)}
                        // collapsed={collapsed === navi.id}
                        isUrl={currentURL === navi.href}
                      />
                    );
                  }
                  return (
                    <NavItemContainer
                      key={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                })}
              </>
            ):currentUser.Role === 'VE' ? (
              <>
                {SidebarVendor.map((navi) => {
                  if (navi.caption) {
                    return (
                      <div className="navCaption text-uppercase mt-4" key={navi.caption}>
                        {navi.caption}
                      </div>
                    );
                  }
                  if (navi.children) {
                    return (
                      <NavSubMenu
                        key={navi.id}
                        icon={navi.icon}
                        title={navi.title}
                        items={navi.children}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        // toggle={() => toggle(navi.id)}
                        // collapsed={collapsed === navi.id}
                        isUrl={currentURL === navi.href}
                      />
                    );
                  }
                  return (
                    <NavItemContainer
                      key={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                })}
              </>
            ) : currentUser.Role === 'HR' ? (
              <>
                {SidebarHr.map((navi) => {
                  if (navi.caption) {
                    return (
                      <div className="navCaption text-uppercase mt-4" key={navi.caption}>
                        {navi.caption}
                      </div>
                    );
                  }
                  if (navi.children) {
                    return (
                      <NavSubMenu
                        key={navi.id}
                        icon={navi.icon}
                        title={navi.title}
                        items={navi.children}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        // toggle={() => toggle(navi.id)}
                        // collapsed={collapsed === navi.id}
                        isUrl={currentURL === navi.href}
                      />
                    );
                  }
                  return (
                    <NavItemContainer
                      key={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                })}
              </>
            ) : currentUser.Role === 'MR' ? (
              <>
                {SidebarMarketing.map((navi) => {
                  if (navi.caption) {
                    return (
                      <div className="navCaption text-uppercase mt-4" key={navi.caption}>
                        {navi.caption}
                      </div>
                    );
                  }
                  if (navi.children) {
                    return (
                      <NavSubMenu
                        key={navi.id}
                        icon={navi.icon}
                        title={navi.title}
                        items={navi.children}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        // toggle={() => toggle(navi.id)}
                        // collapsed={collapsed === navi.id}
                        isUrl={currentURL === navi.href}
                      />
                    );
                  }
                  return (
                    <NavItemContainer
                      key={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                })}
              </>
            ) : currentUser.Role === 'AC' ? (
              <>
                {SidebarAccount.map((navi) => {
                  if (navi.caption) {
                    return (
                      <div className="navCaption text-uppercase mt-4" key={navi.caption}>
                        {navi.caption}
                      </div>
                    );
                  }
                  if (navi.children) {
                    return (
                      <NavSubMenu
                        key={navi.id}
                        icon={navi.icon}
                        title={navi.title}
                        items={navi.children}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        // toggle={() => toggle(navi.id)}
                        // collapsed={collapsed === navi.id}
                        isUrl={currentURL === navi.href}
                      />
                    );
                  }
                  return (
                    <NavItemContainer
                      key={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                })}
              </>
            ): (
              <small className="pb-4 d-block">User Not Login</small>
            )}
          </Nav>
        </div>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;
