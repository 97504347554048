import { React, useState ,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// import SimpleBar from 'simplebar-react';
import {
  Navbar,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Input,
  Label,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  // Badge,
  // Row,
  // Col,
} from 'reactstrap';
import { useNavigate , useLocation} from 'react-router-dom';
import * as Icon from 'react-feather';
// import MessageDD from './MessageDD';
// import NotificationDD from './NotificationDD';
import walletImg from '../../assets/images/NewChangedImages/wallet (1).png'
import user1 from '../../assets/images/users/user4.jpg';
import Logo from '../logo/Logo';
import { ToggleMiniSidebar, ToggleMobileSidebar } from '../../store/customizer/CustomizerSlice';
import ProfileDD from './ProfileDD';
import { logout } from '../../slices/AuthSlice/userSilce';
import { ChangeMyStatus } from '../../slices/Technician/technicianLeadSlice';
import {getProfessionalDetails} from '../../slices/EmployeeSlice/employeeSlice'
import {getCashFreeSesssion , CheckWalletTransactionStatus} from '../../slices/CommonSlice/commonSlice'

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uniqueCode = queryParams.get('UID');
  const isDarkMode = useSelector((state) => state.customizer.isDark);
  const topbarColor = useSelector((state) => state.customizer.topbarBg);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [loading, setLoading] = useState(false)
  const [paymentMode, setPaymentMode] = useState('CF');
  const [walletAmount, setWalletamount] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [apiCallStatus, setApiCallStatus] = useState(null);
  const { user: currentUser } = useSelector((state) => state.user);
 const userID = currentUser.Id
  const dispatch = useDispatch();
  const handleLogoutClick = () => {
    dispatch(logout(navigate('/', { replace: true })));
  };
  useEffect(() => {
    setApiCallStatus(uniqueCode);
  }, [uniqueCode]);

  useEffect(() => {
    dispatch(getProfessionalDetails({ userID }))
      .unwrap()
      .then((res) => {
        if (res) {
        setIsChecked(res.vendor[0].status)
        } else {
          toast.warn(res.message);
        }
      })
      .catch(() => {
      
      });
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    const active = !isChecked;
    dispatch(ChangeMyStatus({ active, currentUser }))
      .unwrap()
      .then((res) => {
        if (res.response_code == '1') {
          toast.success(res.message);
          // navigate('/Subscription/GetSubscriptionDetails');
        } else {
          toast.warn(res.message);
        }
      })
      .catch(() => {
        // handle error
      });
  };
  const toggle3 = () => setModal3(!modal3);
  const handleAmountAdd = () => {
    if (walletAmount == '' || Number.isNaN(Number(walletAmount))) {
      toast.error('Please add valid amount ');
    } else {
      setModal3(false);
      setModal4(true);
    }
  };
  const toggleModal4 = () => {
    // setModal1(!modal1);
    setModal4(!modal4);
  };
  const onchangePaymentMode = (e) => {
    setPaymentMode(e.target.value);
  };
  function generateUniqueTabId() {
    return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
  }
  const cashfreeCall = (params) => {
    const UID = generateUniqueTabId();
    // const orderId = JSON.parse(localStorage.getItem('order_id'));
    const cashfree = window.Cashfree({ mode: 'sandbox' });
    cashfree
      .checkout({
        paymentSessionId: params,
        returnUrl: `https://osbrm.com/Dashboard/VendorDashboard?UID=${UID}`,
        // redirectTarget: '_blank',
      })
      .then(() => {
        console.log('on going redirection');
      })
      .catch((error) => {
        console.error('Error during redirection:', error);
      });
  };

  const AddWalletMoney = () => {
    const user = 'ven'
    setLoading(true);
    dispatch(getCashFreeSesssion({ walletAmount, currentUser,  user}))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res.response_code === '10') {
          setModal4(false);
         localStorage.setItem('vendorWallet',res.res.order_id )
          // setOrderId(res.res.order_id);
          cashfreeCall(res.res.payment_session_id);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const temp = localStorage.getItem('vendorWallet')
    if (apiCallStatus) {
      setLoading(true);
      dispatch(CheckWalletTransactionStatus(temp))
        .unwrap()
        .then((res) => {
          
          setLoading(false);
          if(res.status === 'true'){
            toast.success(res.message)
          }else{
            toast.error(res.message)
          }
          navigate('/Dashboard/VendorDashboard')
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [apiCallStatus]);

  return (
    <>
      <Navbar
        color={topbarColor}
        dark={!isDarkMode}
        light={isDarkMode}
        expand="lg"
        className="topbar"
      >
        {/********Logo*******/}
        <div className="d-none d-lg-flex align-items-center logo-space">
          <Logo />
          <Button
            close
            size="sm"
            className="ms-auto d-sm-block d-lg-none"
            onClick={() => dispatch(ToggleMobileSidebar())}
          />
        </div>
        {/******************************/}
        {/**********Toggle Buttons**********/}
        {/******************************/}
        <div className="d-flex align-items-center">
          <Button
            color={topbarColor}
            className="d-none d-lg-block mx-1 border-0 hov-dd"
            onClick={() => dispatch(ToggleMiniSidebar())}
          >
            <Icon.Menu size={18} />
          </Button>
          {/* <NavbarBrand href="/" className="d-sm-block d-lg-none">
            <LogoWhite />
          </NavbarBrand> */}
          <Button
            color={topbarColor}
            className="d-sm-block d-lg-none border-0 mx-1 hov-dd"
            onClick={() => dispatch(ToggleMobileSidebar())}
          >
            <i className="bi bi-list" />
          </Button>
        </div>

        {/******************************/}
        {/**********Left Nav Bar**********/}
        {/******************************/}

        <Nav className="me-auto d-flex flex-row align-items-center " navbar>
          <NavItem className="d-md-block d-flex">
            {currentUser.Role === 'TE' ? (
              <div className="form-check form-switch" style={{ paddingLeft: '50rem' }}>
                <Input
                  className="form-check-input ps-5 m-1"
                  type="checkbox"
                  role="switch"
                  checked={isChecked}
                  id="flexSwitchCheckChecked"
                  onChange={handleCheckboxChange}
                  // checked
                />
                <Label className="form-check-label m-1" for="flexSwitchCheckChecked">
                  Available
                </Label>
              </div>
            ) : currentUser.Role === 'VE' ? (
              <></>
            ) : (
              <></>
            )}
          </NavItem>
        </Nav>

        <div className="d-flex align-items-center">
          <UncontrolledDropdown>
            <DropdownToggle color="transparent" className=" hov-dd">
              <img src={user1} alt="profile" className="rounded-circle" width="30" />
            </DropdownToggle>
            <DropdownMenu className="ddWidth profile-dd">
              <ProfileDD />
           <div className='d-flex justify-content-between'>
              <div className="p-2 px-3">
                <Button color="info" size="sm" onClick={handleLogoutClick}>
                  Logout
                </Button>
              </div>
              {currentUser.Role === 'VE' ? 
              <div className="" style={{ position: 'relative', marginRight: '20px' }}
               onClick={toggle3}
               >
              <Icon.Plus
                size={25}
                color="#332b5d"
                style={{ position: 'absolute', top: '10px', left: '5px' }}
              />
              {/* <Icon.Briefcase  /> */}
              <img
                src={walletImg}
                alt="walletImg"
                className='mt-1'
                width="60"
                height="40"
                id="edittipExample"
              />

              <UncontrolledTooltip placement="top" target="edittipExample">
                Add Money To Wallet
              </UncontrolledTooltip>
            </div>: null}
            </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Navbar>
      {modal3 ? (
                    <Modal isOpen={modal3} toggle={toggle3} style={{ width: '100%' }}>
                      <ModalHeader toggle={toggle3}>Add Money To Wallet</ModalHeader>
                      <ModalBody>
                        <div className="flex-column align-items-center mt-3 border border-black p-2">
                          <input
                            className="form-control p-2"
                            placeholder="Amount"
                            value={walletAmount}
                            onChange={(e) => setWalletamount(e.target.value)}
                          />
                          <Button
                            className="btn btn-info btn-sm mt-2"
                            onClick={handleAmountAdd}
                          >
                            Add Money
                          </Button>
                        </div>
                      </ModalBody>
                    </Modal>
                  ) : null}
                  {modal4 ? (
                    <Modal isOpen={modal4} toggle={toggleModal4} style={{ width: '100%' }}>
                      <ModalHeader toggle={toggleModal4}>Select Payment Mode</ModalHeader>
                      <ModalBody>
                        <div className=" d-flex gap-5">
                          <FormGroup check>
                            <Input
                              id="Prepaid"
                              type="radio"
                              value="CF"
                              checked={paymentMode === 'CF'}
                              onChange={onchangePaymentMode}
                            />
                            <Label
                              checked
                              style={{
                                fontSize: '16px',
                                marginLeft: '10px',
                                marginTop: '3px',
                                color: '#000',
                              }}
                            >
                              CashFree
                            </Label>
                          </FormGroup>
                          <FormGroup>
                            <FormGroup check>
                              <Input
                                id="Online Payment"
                                type="radio"
                                value="RP"
                                checked={paymentMode === 'RP'}
                                onChange={onchangePaymentMode}
                              />
                              <Label
                                style={{
                                  fontSize: '16px',
                                  marginLeft: '10px',
                                  marginTop: '3px',
                                  color: '#000',
                                }}
                                check
                              >
                                Razor Pay
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </div>
                        <FormGroup>
                          <Button
                            onClick={() => AddWalletMoney('gateway')}
                            disabled={loading}
                            type="submit"
                            color="primary"
                            className="me-2"
                          >
                            {loading && (
                              <span className="spinner-border spinner-border-sm mt-1 me-2"></span>
                            )}
                            Submit
                          </Button>
                        </FormGroup>
                      </ModalBody>
                    </Modal>
                  ) : null}
    </>
  );
};

export default Header;
