import * as Icon from 'react-feather';

const SidebarAdmin = [
  {
    title: 'Dashboard',
    href: '/Dashboard/AdminDashboard',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Home />,
    collapisble: true,
    id: 0,
  },
  {
    title: 'Zone',
    icon: <Icon.Map />,
    id: 1,
    collapisble: true,
    children: [
      {
        title: 'Zone Add',
        href: '/Zone/ZoneAdd',
        icon: <Icon.Disc />,
      },
      {
        title: 'Zone List',
        href: '/Zone/ZoneList',
        icon: <Icon.Disc />,
      },
    ],
  },
  {
    title: 'Advertisement',
    icon: <Icon.Camera />,
    id: 1,
    collapisble: true,
    children: [
      {
        title: 'Add Advertisement',
        href: '/Advertisement/AddAdvertisement',
        icon: <Icon.FilePlus />,
      },
      {
        title: 'Advertisement List',
        href: '/Advertisement/AdvertisementList',
        icon: <Icon.List />,
      },
      {
        title: 'Marketing Coupons List',
        href: '/Advertisement/MarketingCoupons',
        icon: <Icon.List />,
      },
      {
        title: 'Marketing Coupons History',
        href: '/Advertisement/MarketingCouponsHistory',
        icon: <Icon.List />,
      },
      // 
    ],
  },
  {
    title: 'Vendor',
    icon: <Icon.ShoppingBag />,
    id: 2,
    collapisble: true,
    children: [
      {
        title: 'Vendor Add',
        href: '/Vendor/VendorAdd',
        icon: <Icon.UserPlus />,
      },
      {
        title: 'Vendor List',
        href: '/Vendor/VendorList',
        icon: <Icon.List />,
      },
      {
        title: 'Vendor Wallet History',
        href: '/Vendor/VendorWalletHistory',
        icon: <Icon.List />,
      },
    ],
  },
  {
    title: 'Employee',
    href:  '/Employee/Employeelist',
    icon: <Icon.User />,
    id: 3,
    collapisble: true,
    children: [
      {
        title: 'Employee Add',
        href: '/Employee/EmployeeAdd',
        icon: <Icon.Disc />,
      },
      {
        title: 'Employee List',
        href: '/Employee/EmployeeList',
        icon: <Icon.Award />,
      },
      {
        title: 'Candidate List',
        href: 'Employee/CandidateListHR',
        icon: <Icon.Bell />,
      },
      {
        title: 'Rejected Candidate List',
        href: 'Employee/RejectedCandidates',
        icon: <Icon.Bell />,
      },
    ],
  },
  {
    title: 'Banner',
    href: '/Banner/BannerList',
    icon: <Icon.User />,
    id: 4,
    collapisble: true,
    children: [
      {
        title: 'Banner Add',
        href: 'Banner/BannerAdd',
        icon: <Icon.Disc />,
      },
      {
        title: 'Banner List',
        href: '/Banner/BannerList',
        icon: <Icon.Award />,
      },
      {
        title: 'Screen Banner Add',
        href: '/Banner/ScreenBannerAdd',
        icon: <Icon.Disc />,
      },
      {
        title: 'Screen Banner List',
        href: '/Banner/ScreenBannerList',
        icon: <Icon.Award />,
      },
    ],
  },
  {
    title: 'Category',
    href: '/Category/CategoryList',
    icon: <Icon.ShoppingCart />,
    id: 5,
    collapisble: true,
    children: [
      {
        title: 'Category Add',
        href: '/Category/CategoryAdd',
        icon: <Icon.Disc />,
      },
      {
        title: 'Category List',
        href: '/Category/CategoryList',
        icon: <Icon.Disc />,
      },
    ],
  },
  {
    title: 'Sub Category',
    href: '/SubCategory/SubCategoryAdd',
    icon: <Icon.ShoppingCart />,
    id: 6,
    collapisble: true,
    children: [
      {
        title: 'Sub Category Add',
        href: '/SubCategory/SubCategoryAdd',
        icon: <Icon.Disc />,
      },
      {
        title: 'Sub Category List',
        href: '/SubCategory/SubCategoryList',
        icon: <Icon.Disc />,
      },
    ],
  },
  {
    title: 'Sevices',
    href: '/Services/ServicesList',
    icon: <Icon.List />,
    id: 7,
    collapisble: true,
    children: [
      {
        title: 'Services Add',
        href: '/Services/ServicesAdd',
        icon: <Icon.Disc />,
      },
      {
        title: 'Services List',
        href: '/Services/ServicesList',
        icon: <Icon.Disc />,
      },
      {
        title: 'Add Banner',
        href: '/Services/AddBannerServices',
        icon: <Icon.FilePlus />,
      },
      //
    ],
  },
  {
    title: 'Requests',
    href: '/admin/RequestsForProducts',
    icon: <Icon.List />,
    id: 8,
    collapisble: true,
    children: [
      {
        title: 'Product Requests',
        href: '/RequestsForProducts/ProductRequestForVendor',
        icon: <Icon.Disc />,
      },
      {
        title: 'Request History',
        href: '/RequestsForProducts/HistoryOfRequests',
        icon: <Icon.Disc />,
      },
    ],
  },
  // {
  //   title: 'Special Offer',
  //   href: '/ecom',
  //   icon: <Icon.EyeOff />,
  //   id: 5,
  //   collapisble: true,
  //   children: [
  //     {
  //       title: 'Special Offer Add',
  //       href: '/specialoffer/specialoffersadd',
  //       icon: <Icon.Disc />,
  //     },
  //   ],
  // },
  // {
  //   title: 'Export / Import',
  //   href: '/ecom',
  //   icon: <Icon.File />,
  //   id: 6,
  // },
  // {
  //   title: 'Billing Estimation',
  //   href: '/ecom',
  //   icon: <Icon.Briefcase />,
  //   id: 7,
  // },
  // {
  //   title: 'Notification',
  //   href: '/ecom',
  //   icon: <Icon.AlertCircle />,
  //   id: 8,
  // },
   
  
  {
    title: 'Orders',
    href: '',
    icon: <Icon.List />,
    id: 9,
    collapisble: true,
    children: [
      {
        title: 'Unassign Orders',
        href: '/Orders/PendingOrders',
        icon: <Icon.Disc />,
      },
      {
        title: 'Cancelled Orders',
        href: '/Orders/CancelledOrders',
        icon: <Icon.Disc />,
      },
      {
        title: 'Completed Orders',
        href: '/Orders/CompletedOrders',
        icon: <Icon.Disc />,
      },
      
    ],
    
  }, // 
  // {
  //   title: 'Password',
  //   href: '/ecom',
  //   icon: <Icon.Lock />,
  //   id: 12,
  // },
  // {
  //   title: 'Set ReferralCost',
  //   href: '',
  //   icon: <Icon.RefreshCw />,
  //   id: 13,
  // },
  // {
  //   title: 'Customer List',
  //   href: '/Customer/CustomerList',
  //   icon: <Icon.RefreshCw />,
  //   id: 13,
  // },
  {
    title: 'Sales Report',
    href: '/ecom',
    icon: <Icon.FolderPlus />,
    id: 10,
    collapisble: true,
    children: [
      {
        title: 'Service Sales Report',
        href: '/SalesReport/ServiceSalesReport',
        icon: <Icon.Disc />,
      },
      {
        title: 'Vendor Purchase Report  ',
        href: '/SalesReport/VendorPurchaseReport',
        icon: <Icon.Disc />,
      },
      
    ],
  },
  {
    title: 'Referral',
    href: '/ecom',
    icon: <Icon.FolderPlus />,
    id: 10,
    collapisble: true,
    children: [
      {
        title: 'Set Referral Cost',
        href: '/Referral/SetReferralCost',
        icon: <Icon.Disc />,
      },
      {
        title: 'Referral History',
        href: '/Referral/GetReferralHistory',
        icon: <Icon.Disc />,
      },
      
    ],
  },
  {
    title: 'Customer',
    href: '/ecom',
    icon: <Icon.User />,
    id: 11,
    collapisble: true,
    children: [
      {
        title: 'User Managment',
        href: '/Customer/CustomerList',
        icon: <Icon.Disc />,
      },
      // {
      //   title: 'Update Balance',
      //   href: '/Customer/CustomerUpdateBalance',
      //   icon: <Icon.Disc />,
      // },
      {
        title: 'Wallet History',
        href: '/Customer/GetWalletHistoryCustomer',
        icon: <Icon.Disc />,
      },
      
    ],
  },
  {
    title: 'Subscription Master',
    href: '',
    icon: <Icon.UserPlus />,
    id: 12,
    collapisble: true,
    children: [
      {
        title: 'Subscription Details',
        href: '/Subscription/GetSubscriptionDetails',
        icon: <Icon.List />,
      },
      {
        title: 'Discount Master list',
        href: '/Subscription/DiscountMasterList',
        icon: <Icon.List />,
      },
    ],
  },
  {
    title: 'Account Wallet',
    href: '',
    icon: <Icon.UserPlus />,
    id: 12,
    collapisble: true,
    children: [
      {
        title: 'Add Balance ',
        href: '/Accountant/AccountantWallet',
        icon: <Icon.List />,
      },
      // {
      //   title: 'Discount Master list',
      //   href: '/Subscription/DiscountMasterList',
      //   icon: <Icon.List />,
      // },
    ],
  },
  // {
  //   title: 'Packages',
  //   href: '',
  //   icon: <Icon.UserPlus />,
  //   id: 13,
  //   collapisble: true,
  //   children: [
  //     {
  //       title: 'Add Package',
  //       href: '/Packages/AddPackages',
  //       icon: <Icon.List />,
  //     },
  //     {
  //       title: 'Packages List',
  //       href: '/Packages/PackageList',
  //       icon: <Icon.List />,
  //     },
  //   ],
  // },
  {
    title: 'Tips',
    href: '',
    icon: <Icon.UserPlus />,
    id: 13,
    collapisble: true,
    children: [
      {
        title: 'Add Tips',
        href: '/Tips/AddTips',
        icon: <Icon.List />,
      },
      {
        title: 'Tips List',
        href: '/Tips/TipsList',
        icon: <Icon.List />,
      },
    ],
  },
  // {
  //   title: 'Pending Orders',
  //   href: '/Orders/PendingOrders',
  //   icon: <Icon.RefreshCw />,
  //   id: 13,
  // },
  // {
  //   title: 'Owner Shop',
  //   href: '/ecom',
  //   icon: <Icon.ShoppingBag />,
  //   id: 14,
  // },
  // {
  //   title: 'Order List',
  //   href: '/ecom',
  //   icon: <Icon.List />,
  //   id: 15,
  // },
  // {
  //   title: 'Stock Management',
  //   href: '/ecom',
  //   icon: <Icon.StopCircle />,
  //   id: 16,
  // },
  // {
  //   title: 'Refer Earn',
  //   href: '/ecom',
  //   icon: <Icon.Moon />,
  //   id: 17,
  // },
  // {
  //   title: 'Package Offer',
  //   href: '/ecom',
  //   icon: <Icon.PhoneOff />,
  //   id: 18,
  // },
  // {
  //   title: 'Pincode',
  //   href: '/ecom',
  //   icon: <Icon.MousePointer />,
  //   id: 19,
  // },
];

export default SidebarAdmin;
