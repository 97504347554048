import * as Icon from 'react-feather';

const SidebarCall = [
  {
    title: 'Dashboard',
    href: '/Dashboard/Calldashboard',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Home/>,
    id:0,
  },
  // {
  //   title: 'Technician',
  //   href: '/Dashboard/Calldashboard',
  //   icon: <Icon.User />,
  //   id: 1,
  // },
  // {
  //   title: 'Available Technician',
  //   href: '/Dashboard/Calldashboard',
  //   icon: <Icon.Eye />,
  //   id: 2,
  // },
  // {
  //   title: 'Service Completion',
  //   href: '/Dashboard/Calldashboard',
  //   icon: <Icon.User />,
  //   id: 3,
  // },
  // {
  //   title: 'Payment Status',
  //   href: '/Dashboard/Calldashboard',
  //   icon: <Icon.Play />,
  //   id: 4,
  //   collapisble: true,
  //   children: [
  //     {
  //       title: 'Payment Add',
  //       href: '/Dashboard/Calldashboard',
  //       icon: <Icon.Disc />,
  //     },
  //     {
  //       title: 'Payment List',
  //       href: '/Dashboard/Calldashboard',
  //       icon: <Icon.Award />,
  //     },
  //   ],
  // },
  // {
  //   title: 'Escalate HR/Admin',
  //   href: '/Dashboard/Calldashboard',
  //   icon: <Icon.Disc />,
  //   id: 5,
  //   collapisble: true,
  //   children: [
  //     {
  //       title: 'Escalate Add',
  //       href: '/Dashboard/Calldashboard',
  //       icon: <Icon.Disc />,
  //     },
  //     {
  //       title: 'Escalate List',
  //       href: '/Dashboard/Calldashboard',
  //       icon: <Icon.Award />,
  //     },
  //   ],
  // },
  // {
  //   title: 'Track Technician',
  //   href: '/ecom',
  //   icon: <Icon.MapPin />,
  //   id: 6,
  // },
  // {
  //   title: 'Manage Customer',
  //   href: '/ecom',
  //   icon: <Icon.Edit />,
  //   id: 7,
  // },
  // {
  //   title: 'Customer Details',
  //   href: '/ecom',
  //   icon: <Icon.User />,
  //   id: 8,
  // },
  // {
  //   title: 'Employee Records',
  //   href: '/inventory/inventorylist',
  //   icon: <Icon.List />,
  //   id: 9,
  //   collapisble: true,
  //   children: [
  //     {
  //       title: 'Employee Add',
  //       href: '/inventory/inventoryadd',
  //       icon: <Icon.Disc />,
  //     },
  //     {
  //       title: 'Employee List',
  //       href: '/inventory/inventorylist',
  //       icon: <Icon.Award />,
  //     },
  //   ],
  // },
  // {
  //   title: 'Material Availability',
  //   href: '/ecom',
  //   icon: <Icon.Briefcase />,
  //   id: 10,
  // },
  // {
  //   title: 'Inventory',
  //   href: '/inventory/inventorylist',
  //   icon: <Icon.File />,
  //   id: 11,
  //   collapisble: true,
  //   children: [
  //     {
  //       title: 'Inventory Add',
  //       href: '/inventory/inventoryadd',
  //       icon: <Icon.Disc />,
  //     },
  //     {
  //       title: 'Inventory List',
  //       href: '/inventory/inventorylist',
  //       icon: <Icon.Award />,
  //     },
  //   ],
  // },
];

export default SidebarCall;
